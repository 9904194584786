import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import AppContext from 'context/Context';
import classNames from 'classnames';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import Footer from 'components/footer/Footer';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import { AuthStateContext, AuthDispatchContext, logoutSuccess, setUserLogin, setAccessToken } from "context/auth"
import { sendData, parseJwt,sendDataLogin } from 'helpers/utils';
import withRedirect from "components/hoc/withRedirect";

const MainLayout = ({ redirectUrl, setRedirectUrl, setRedirect }) => {
  const state = useContext(AuthStateContext);
  const dispatch = useContext(AuthDispatchContext);
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  // const isChat = pathname.includes('chat');

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);


  const checkAutoRedirect = () => {
    var access = localStorage.getItem("access");
    var refresh = localStorage.getItem("refresh");
    var curTime = new Date();
    curTime = Math.floor(curTime.getTime()/1000.0);
    var refreshExp = false;
    // checking If Refresh Token Is Present
    if(refresh !== null){
        // checking refresh Token Has Expired
        if(curTime > parseJwt(refresh).exp){
            refreshExp = true;
        }
    }
    if(access !== null && refresh !== null && !refreshExp){
      let accessExp = parseJwt(access).exp;
      let hasError = false;
      if(curTime > accessExp){
          sendData(window.location.pathname.replace("/",""),"login/refresh",{access,refresh})
          .then((res)=>{
            setAccessToken(dispatch, res.data.access)
            access = res.data.access
          })
          .catch((err)=>
           console.log("mainlayout",err)
        )}
      if(!hasError){
        // setting Up Login when  Refreshing The Page
        setUserLogin(dispatch,access,refresh)
      }
      else{
        // Token Time OF access Token And Refresh Token Has Expired
        logoutSuccess(dispatch)
      }

    }
    else{
      // logout When No Token Is Available
      logoutSuccess(dispatch)
      setRedirect(true)
      setRedirectUrl('/signin')
    }


  }

  useEffect(checkAutoRedirect,[redirectUrl, setRedirectUrl, setRedirect])

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      <ProductProvider>
        <div className={classNames('content', { 'pb-0': isKanban })}>
          <NavbarTop />
            {/*------ Main Routes ------*/}
          <Outlet />
          {!isKanban && <Footer />}
        </div>
      </ProductProvider>
    </div>
  );
};

export default withRedirect(MainLayout);
