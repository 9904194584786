/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Flex from '../Flex';

export const AdvanceTablePagination = ({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageCount,
  pageIndex,
  gotoPage
}) => {
  const maxPageNumbersToShow = 10;
  const [startPage, setStartPage] = useState(0);

  const endPage = Math.min(startPage + maxPageNumbersToShow, pageCount);

  const handlePreviousSet = () => {
    const newStartPage = Math.max(startPage - maxPageNumbersToShow, 0);
    setStartPage(newStartPage);
    gotoPage(newStartPage);
  };

  const handleNextSet = () => {
    const newStartPage = Math.min(startPage + maxPageNumbersToShow, pageCount - maxPageNumbersToShow);
    setStartPage(newStartPage);
    gotoPage(newStartPage);
  };

  return (
    <Flex alignItems="center" justifyContent="center">
      <Button
        size="sm"
        variant="falcon-default"
        onClick={handlePreviousSet}
        className={classNames({ disabled: startPage === 0 })}
      >
        <FontAwesomeIcon icon="chevron-left" />
      </Button>
      <ul className="pagination mb-0 mx-1">
        {Array.from(Array(endPage - startPage).keys()).map((page, index) => (
          <li
            key={startPage + page}
            className={classNames({ active: pageIndex === startPage + page })}
          >
            <Button
              size="sm"
              variant="falcon-default"
              className={classNames('page', {
                'me-1': startPage + page + 1 !== pageCount
              })}
              onClick={() => gotoPage(startPage + page)}
            >
              {startPage + page + 1}
            </Button>
          </li>
        ))}
      </ul>
      <Button
        size="sm"
        variant="falcon-default"
        onClick={handleNextSet}
        className={classNames({ disabled: endPage === pageCount })}
      >
        <FontAwesomeIcon icon="chevron-right" />
      </Button>
    </Flex>
  );
};

export default AdvanceTablePagination;
