export const dashboardRoutes = {
  label: '',
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to: '/'
    },
    {
      name: 'Invoice',
      icon: 'calendar-alt',
      to: '/e-commerce/invoice',
      active: true
    },
    {
      name: 'Lead',
      icon: 'plus',
      to: '/leadinfo/leaddetails',
      active: true,

    },
    {
      name: 'Pending Orders',
      icon: 'shopping-cart',
      active: true,
      children: [
        {
          name: 'Pending list',
          to: '/pending/pending-list',
          active: true
        },
        {
          name: 'Pending Design',
          to: '/pending/pending-design',
          active: true
        }
      ]
    },
    {
      name: 'Orders',
      icon: 'shopping-cart',
      active: true,
      children: [
        {
          name: 'Order List',
          to: '/orders/order-process',
          active: true
        },
        {
          name: 'Order Details',
          to: '/orders/order-list',
          active: true
        },
        /*{
          name: 'Order details',
          to: '/orders/order-details',
          active: true
        },*/

      ]
    },
    {
      name: 'Products',
      icon: 'shopping-cart',
      active: true,
      children: [
        {
          name: 'Product List',
          to: '/products/product-list',
          active: true
        },

      ]
    },{
      name: 'Inventory',
      icon: 'shopping-cart',
      active: true,
      children: [
        {
          name: 'Requirements List',
          to: '/requirements/requirement-list',
          active: true
        },

      ]
    },
    {
      name: 'Site Numbers',
      icon: 'phone',
      active: true,
      children: [
        {
          name: 'Site Number list',
          to: '/sitenumber/sitenumber-list',
          active: true
        },

      ]
    },
    {
      name: 'Email',
      icon: 'envelope-open',
      active: true,
      children: [
        {
          name: 'Inbox',
          to: '/email/inbox',
          active: true
        },
        {
          name: 'Email detail',
          to: '/email/email-detail',
          active: true
        },
        {
          name: 'Compose',
          to: '/email/compose',
          active: true
        }
      ]
    },
    {
      name: 'Events',
      icon: 'calendar-day',
      active: true,
      children: [
        {
          name: 'Create an event',
          to: '/events/create-an-event',
          active: true
        },
        {
          name: 'Event detail',
          to: '/events/event-detail',
          active: true
        },
        {
          name: 'Event list',
          to: '/events/event-list',
          active: true
        }
      ]
    },
    {
      name: 'E Commerce',
      icon: 'shopping-cart',
      active: true,
      children: [
        {
          name: 'Product',
          active: true,
          children: [
            {
              name: 'Product list',
              to: '/e-commerce/product/product-list',
              active: true
            },
            {
              name: 'Product grid',
              to: '/e-commerce/product/product-grid',
              active: true
            },
            {
              name: 'Product details',
              to: '/e-commerce/product/product-details',
              active: true
            }
          ]
        },
        {
          name: 'Customers',
          to: '/e-commerce/customers',
          active: true
        },
        {
          name: 'Customer details',
          to: '/e-commerce/customer-details',
          active: true
        },
        {
          name: 'Shopping cart',
          to: '/e-commerce/shopping-cart',
          active: true
        },
        {
          name: 'Checkout',
          to: '/e-commerce/checkout',
          active: true
        },
        {
          name: 'Billing',
          to: '/e-commerce/billing',
          active: true
        }
      ]
    },
    {
      name: 'Social',
      icon: 'share-alt',
      active: true,
      children: [
        {
          name: 'Feed',
          to: '/social/feed',
          active: true
        },
        {
          name: 'Activity log',
          to: '/social/activity-log',
          active: true
        },
        {
          name: 'Notifications',
          to: '/social/notifications',
          active: true
        },
        {
          name: 'Followers',
          to: '/social/followers',
          active: true
        }
      ]
    }
  ]
};

export default [dashboardRoutes];
