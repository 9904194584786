import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import React, { useState,useEffect } from 'react';
import { Card, Dropdown,Row,Col,Form,Button } from 'react-bootstrap';

import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import OrderHeader from './OrderHeader';
import { ResponseFromServer } from 'helpers/utils';

const statusMetadata = [
  {
    "name": "pending",
    "value": "Pending",
    "attributes": [
      {
        "name": "stateColor",
        "value": "gray"
      }
    ]
  },
  {
    "name": "completed",
    "value": "Completed",
    "attributes": [
      {
        "name": "stateColor",
        "value": "green"
      }
    ]
  },
  {
    "name": "in_progress",
    "value": "In Progress",
    "attributes": [
      {
        "name": "stateColor",
        "value": "blue"
      }
    ]
  },
  {
    "name": "customer_review",
    "value": "Customer Review",
    "attributes": [
      {
        "name": "stateColor",
        "value": "red"
      }
    ]
  },
  {
    "name": "fabric_unavailable",
    "value": "Fabric Unavailable",
    "attributes": [
      {
        "name": "stateColor",
        "value": "red"
      }
    ]
  },
  {
    "name": "production_issue",
    "value": "Production Issue",
    "attributes": [
      {
        "name": "stateColor",
        "value": "red"
      }
    ]
  },
  {
    "name": "packaging_issue",
    "value": "Packaging Issue",
    "attributes": [
      {
        "name": "stateColor",
        "value": "red"
      }
    ]
  },
  {
    "name": "shipping_ready",
    "value": "Ready for Shipping",
    "attributes": [
      {
        "name": "stateColor",
        "value": "blue"
      }
    ]
  },
  {
    "name": "shipped",
    "value": "Shipped",
    "attributes": [
      {
        "name": "stateColor",
        "value": "blue"
      }
    ]
  },
  {
    "name": "delivered",
    "value": "Delivered",
    "attributes": [
      {
        "name": "stateColor",
        "value": "green"
      }
    ]
  }
];
const getStatusDetails = (statusName) => {
  const status = statusMetadata.find(item => item.name === statusName);
  return status ? {
    value: status.value,
    color: status.attributes.find(attr => attr.name === 'stateColor').value
  } : { value: 'Pending', color: 'gray'}; // Fallback values
};



const columns = [
  {
    Header: '#',
    accessor: (_, i) => i + 1,
    id: 'index',
    disableSortBy: true,
  },
  {
    accessor: 'orderId',
    Header: 'Process Status',
    Cell: ({ row }) => {
      const originalstatuses = row.original.orderStatusList || []; // Access order statuses
      const statuses = originalstatuses
        .filter(status => status.trim() !== '')
        .map(status => status.replace(/\s+/g, ':')) // Replace spaces with colons
        .filter(status =>
          !['parcelStatus:', 'productStatus:', 'designStatus:', 'printStatus:'].some(prefix => status.startsWith(prefix))
        );
        const hasDesignStatus = statuses.some(status => status.startsWith('orderStatus:'));


        // Add statuses if not present
        if (!hasDesignStatus) {
          statuses.push('Order');
        }

      return (

        <div>
        <div><a href={`/orders/order-list?id=${row.original.orderId}`}>Order Id: {row.original.orderId}</a></div>
        <div>
        {row.original.deliveryType === 'EXPRESS' && (
           <span style={{color: 'orange',fontWeight:"900",fontSize:"12px"}}>{row.original.deliveryType}</span>
         )}
        </div>
          {statuses.map((status, index) => {
            const [processName, statusName] = status.split(':');
            const capitalizedProcessName = processName.charAt(0).toUpperCase() + processName.slice(1);
            const name = capitalizedProcessName.replace("Status", " ").trim();

            // Get status details for the order status
            const { value, color } = getStatusDetails(statusName);

            return (
              <div key={index} style={{ margin: '2px 0', display: 'inline-block' }}>
                <div className="rounded-pill badge" style={{ backgroundColor: color, color: 'white',minWidth:"100px",lineHeight:"12px" }}>
                  {value} {/* Use value from getStatusDetails */}
                </div>
              </div>
            );
          })}

        </div>
      );
    }
  },

  {
    accessor: 'orderDate',
    Header: 'Order Date',
    cellProps: ''
  },
  {
    accessor: 'deliveryDate',
    Header: 'Delivery Date',
    headerProps:'',
    cellProps: ''
  },
  {
    accessor: 'totalAmount',
    Header: 'Total Paid & Due',
    Cell: ({ row }) => (

      <div>
      <div>
        <div style={{fontWeight:"900" }}>{row.original.productName}</div>
        <div style={{ color: 'black'}}>
        Color =
        <span style={{ color: 'black'}}> {row.original.productColor}</span>
      </div>
      </div>
      <div><span>Total = <span style={{color: 'blue'}}>{row.original.totalAmount}</span></span>
      <span style={{marginLeft:"5px"}}>Paid = <span style={{color: 'blue'  }}>{row.original.paidAmount}</span></span></div>
      <div><span >Due Amount = <span style={{color: 'Orange'  }}>{row.original.dueAmount}</span></span></div>

      </div>

    ),
    headerProps: {
      className: 'header-class',
      style: { textAlign: 'left' },
    },
    cellProps: {
      className: 'cell-class',
      style: { color: 'black',maxWidth:"190px" },
    },
  },

{
accessor: 'sizeQuantities',
Header: 'Size Quantity',
Cell: ({ row }) => {
  const sizes = row.original.sizeQuantities; // Access size quantities
  const totalQuantity = sizes.reduce((total, sizeQuantity) => {
    const [, quantity] = sizeQuantity.split(' '); // Split to get quantity
    return total + parseInt(quantity, 10); // Sum the quantities
  }, 0);

  const rows = []; // Initialize rows array

  // Push the total quantity row
  rows.push(
    <div key="total-quantity " style={{ margin: '0' }}>
      <div style={{ padding:"0px" }}>
        Total: <span style={{ color: 'red' }}>{totalQuantity}</span>
      </div>
    </div>
  );

  // Create rows for pairs of size quantities
  const sizeRows = sizes
    .filter(sizeQuantity => {
      const [, quantity] = sizeQuantity.split(' '); // Split to get quantity
      return parseInt(quantity, 10) > 0; // Filter sizes with quantity greater than 0
    })
    .reduce((acc, sizeQuantity, index) => {
      // Group sizes into pairs
      if (index % 2 === 0) {
        acc.push([sizeQuantity]); // Start a new row
      } else {
        acc[acc.length - 1].push(sizeQuantity); // Add to the last row
      }
      return acc;
    }, []);

  // Map size rows to JSX
  sizeRows.forEach((pair, rowIndex) => {
    rows.push(
      <tr key={rowIndex}>
        {pair.map((sizeQuantity, index) => {
          const [size, quantity] = sizeQuantity.split(' '); // Split size and quantity
          return (
            <td key={index} className="p-0">
              <span >
                <strong >{size} = </strong><span style={{ color: 'blue' }}>{quantity}</span>
              </span>
            </td>
          );
        })}
      </tr>
    );
  });

  return (
    <table style={{ textAlign: 'left', width: '100%' }}>
      <tbody>
        {rows.length > 0 && rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            <td className="p-0">
              {row}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
},
headerProps: {
  className: 'header-class',
  style: { textAlign: 'left' },
},
cellProps: {
  className: 'cell-class',
  style: { color: 'black' },
},
},
{
accessor: 'orderStatusList',
Header: 'Process Status',
Cell: ({ row }) => {
  const originalstatuses = row.original.orderStatusList || []; // Access order statuses

  // Map and filter to get desired statuses
  const statuses = originalstatuses
    .filter(status => status.trim() !== '')
    .map(status => status.replace(/\s+/g, ':')) // Replace spaces with colons
    .filter(status =>
      !['orderStatus:'].some(prefix => status.startsWith(prefix))
    );

  // Check if specific statuses are present
  const hasDesignStatus = statuses.some(status => status.startsWith('designStatus:'));
  const hasProductStatus = statuses.some(status => status.startsWith('productStatus:'));
  const hasPrintStatus = statuses.some(status => status.startsWith('printStatus:'));
  const hasParcelStatus = statuses.some(status => status.startsWith('parcelStatus:'));

  // Add statuses if not present
  if (!hasDesignStatus) {
    statuses.push('Design: Pending');
  }
  if (!hasProductStatus) {
    statuses.push('Product: Pending');
  }
  if (!hasPrintStatus) {
    statuses.push('Print: Pending');
  }
  if (!hasParcelStatus) {
    statuses.push('Parcel: Pending');
  }



  // Initialize rows for rendering
  const rows = [];

  statuses.forEach((status, index) => {
    const [processName, statusName] = status.split(':');
    const capitalizedProcessName = processName.charAt(0).toUpperCase() + processName.slice(1);
    const name = capitalizedProcessName.replace("Status", " ").trim();
    const { value, color } = getStatusDetails(statusName);

    // Calculate the row index
    const rowIndex = Math.floor(index / 1);

    // Directly initialize the row
    if (!rows[rowIndex]) {
      rows[rowIndex] = [];
    }

    // Push the status element into the corresponding row
    rows[rowIndex].push(
      <div key={`${processName}-${statusName}-${index}`} className="d-flex pb-1">
        <strong className="p-0" style={{width:"60px"}}>{name}: </strong>
        <div className="rounded-pill badge " style={{ backgroundColor: color, color: 'white',minWidth:"100px",lineHeight:"12px" }}>
        <span>{value}</span>
        </div>
      </div>
    );
  });

  return (
    <table style={{  textAlign: 'left' }}>
      <tbody>
        {rows.length > 0 &&
          rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} className="p-0">
                  {cell}
                </td>
              ))}
            </tr>
          ))
        }
      </tbody>
    </table>
  )
},
headerProps: {
  className: 'header-class',
  style: { textAlign: 'left' },
},
cellProps: {
  className: 'cell-class',
  style: { color: 'black',paddingTop:"15px",paddingBottom:"15px"},
},

},





];

const OrderProcess = () =>  {
  const [orderFilter ,setOrderFilter] =useState([])


  return (

    <AdvanceTableWrapper
      columns={columns}
      data={orderFilter}
      sortable
      pagination
      perPage={500}
    >
      <OrderHeader setOrderFilter={setOrderFilter}/>
      <Card className="mb-3">
        <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Orders</h5>
          </Col>
          </Row>

        </Card.Header>

        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>

          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default OrderProcess;
