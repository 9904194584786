import { fabric } from "fabric";
fabric.CurvedText = fabric.util.createClass(fabric.Object, {
  type: 'curved-text',
  diameter: 250,
  kerning: 0,
  text: '',
  flipped: false,
  fill: '#000',
  fontFamily: 'Times New Roman',
  fontSize: 24, // in px
  fontWeight: 'normal',
  fontStyle: '', // "normal", "italic" or "oblique".
  cacheProperties: fabric.Object.prototype.cacheProperties.concat(
      'diameter', 'kerning', 'flipped', 'fill', 'fontFamily', 'fontSize',
      'fontWeight', 'fontStyle', 'strokeStyle', 'strokeWidth'
  ),
  strokeStyle: null,
  strokeWidth: 0,

  // Initialize function
  initialize: function (text, options) {

      options || (options = {});
      this.text = text || ""; // Ensure text is not undefined
      this.callSuper('initialize', options);

      // Lock uniform scaling
      this.set('lockUniScaling', true);
      // this.on('scaling', this._onScaling.bind(this)); // Fires during scaling
      this.on('modified', this._onModified.bind(this));   // Fires after scaling end

      // Debug: Ensure text is valid
      if (!this.text || !this.text.trim()) {

          this.set('width', 0);
          this.set('height', 0);
          return;
      }

      // Create circular text canvas
      const canvas = this.getCircularText();
      if (!canvas) {

          this.set('width', 0);
          this.set('height', 0);
          return;
      }

      // Trim the canvas to remove excess transparent pixels
      this._trimCanvas(canvas);

      // Set dimensions
      this.set('width', canvas.width);
      this.set('height', canvas.height);

      // Debug: Log dimensions

  },

  getCircularText: function () {

      var text = this.text,
          diameter = this.diameter,
          flipped = this.flipped,
          kerning = this.kerning,
          fill = this.fill,
          inwardFacing = true,
          startAngle = 0,
          canvas = fabric.util.createCanvasElement(),
          ctx = canvas.getContext('2d', { willReadFrequently: true }),
          cw, x,
          clockwise = -1;

      if (flipped) {
          startAngle = 180;
          inwardFacing = false;
      }
      startAngle *= Math.PI / 180;
      var d = document.createElement('div');
      d.style.fontFamily = this.fontFamily;
      d.style.whiteSpace = 'nowrap';
      d.style.fontSize = this.fontSize + 'px';
      d.style.fontWeight = this.fontWeight;
      d.style.fontStyle = this.fontStyle;
      d.textContent = text;
      document.body.appendChild(d);
      var textHeight = d.offsetHeight;
      document.body.removeChild(d);
      canvas.width = canvas.height = diameter;
      ctx.font = this._getFontDeclaration();
      if (inwardFacing) { text = text.split('').reverse().join('') }
      ctx.translate(diameter / 2, diameter / 2);
      startAngle += (Math.PI * !inwardFacing);
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';
      for (x = 0; x < text.length; x++) {
          cw = ctx.measureText(text[x]).width;
          startAngle += ((cw + (x == text.length - 1 ? 0 : kerning)) / (diameter / 2 - textHeight)) / 2 * -clockwise;
      }
      ctx.rotate(startAngle);
      for (x = 0; x < text.length; x++) {
          cw = ctx.measureText(text[x]).width;
          ctx.rotate((cw / 2) / (diameter / 2 - textHeight) * clockwise);
          if (this.strokeStyle && this.strokeWidth) {
              ctx.strokeStyle = this.strokeStyle;
              ctx.lineWidth = this.strokeWidth;
              ctx.miterLimit = 2;
              ctx.strokeText(text[x], 0, (inwardFacing ? 1 : -1) * (0 - diameter / 2 + textHeight / 2));
          }
          ctx.fillStyle = fill;
          ctx.fillText(text[x], 0, (inwardFacing ? 1 : -1) * (0 - diameter / 2 + textHeight / 2));
          ctx.rotate((cw / 2 + kerning) / (diameter / 2 - textHeight) * clockwise);
      }
      return canvas;
  },

  _getFontDeclaration: function () {
      return [
          (fabric.isLikelyNode ? this.fontWeight : this.fontStyle),
          (fabric.isLikelyNode ? this.fontStyle : this.fontWeight),
          this.fontSize + 'px',
          (fabric.isLikelyNode ? ('"' + this.fontFamily + '"') : this.fontFamily)
      ].join(' ');
  },

  _trimCanvas: function (canvas) {
      var ctx = canvas.getContext('2d', { willReadFrequently: true }),
          w = canvas.width,
          h = canvas.height,
          pix = { x: [], y: [] },
          n,
          imageData = ctx.getImageData(0, 0, w, h),
          fn = function (a, b) { return a - b };

      let counter = 0;
      for (var y = 0; y < h; y++) {
          for (var x = 0; x < w; x++) {
              if (imageData.data[((y * w + x) * 4) + 3] > 0) {
                  counter++;
                  pix.x.push(x);
                  pix.y.push(y);
              }
          }
      }

      pix.x.sort(fn);
      pix.y.sort(fn);
      n = pix.x.length - 1;

      w = pix.x[n] - pix.x[0];
      h = pix.y[n] - pix.y[0];
      var cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

      canvas.width = w;
      canvas.height = h;
      ctx.putImageData(cut, 0, 0);
  },

  _set: function (key, value) {
        this.callSuper('_set', key, value);
}
  ,
  _applyScaling: function () {

          this.fontSize *= this.scaleX || 1;
          this.diameter *= this.scaleX || 1;

          // Reset scale values to prevent cumulative scaling
          this.scaleX = 1;
          this.scaleY = 1;

          this.canvas?.renderAll();
      }
  ,
_render: function (ctx) {
    // Create the curved text canvas
    const canvas = this.getCircularText();
    this._trimCanvas(canvas);

    // Update dimensions dynamically
    this.set('width', canvas.width);
    this.set('height', canvas.height);

    // Draw the curved text
    ctx.drawImage(canvas, -this.width / 2, -this.height / 2, this.width, this.height);


},
  toObject: function () {
      return fabric.util.object.extend(this.callSuper('toObject'), {
          text: this.text,
          diameter: this.diameter,
          kerning: this.kerning,
          flipped: this.flipped,
          fill: this.fill,
          fontFamily: this.fontFamily,
          fontSize: this.fontSize,
          fontWeight: this.fontWeight,
          fontStyle: this.fontStyle,
          strokeStyle: this.strokeStyle,
          strokeWidth: this.strokeWidth,
      });
  }



});



fabric.CurvedText.prototype._onScaling = function () {
    // Update bounding box dimensions dynamically
    switch (this.key) {
        case 'scaleX':
            this.fontSize *= this.value;
            this.diameter *= this.value;
            this.width *= this.value;
            this.scaleX = 1;
            if (this.width < 1) { this.width = 1; }
            this.callSuper('_set', this.key, this.value);
            break;

        case 'scaleY':
            this.height *= this.value;
            this.scaleY = 1;
            if (this.height < 1) { this.height = 1; }
            this.callSuper('_set', this.key, this.value);
            break;

        default:
            this.callSuper('_set', this.key, this.value);
            break;
    }


};




// Scale end handler: Redraw the curved text
fabric.CurvedText.prototype._onModified = function () {
    this._applyScaling();
};
