import React, { useEffect, useState } from "react";
import { ResponseFromServer, sendData } from 'helpers/utils';
import { Card, Col, Row, Form, Modal, Button} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

const SiteNumber = () => {
  const [siteNumberData, setSiteNumberData] = useState([]);
  const [agent, setAgent] = useState({});
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleShow = (agentData) => {
    viewAgent();
    setAgent(agentData);
    setSelectedAgents([]);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const agentId = Number(value);
    if (checked) {
      setSelectedAgents((prev) => [...prev, agentId]);
    } else {
      setSelectedAgents((prev) => prev.filter(id => id !== agentId));
    }
  };

  const handleView = async () => {
    try {
      const res = await ResponseFromServer('sitenumber/sitenumber-list', `agent/available`);
      const groupedData = res.data.map(item => ({
        type: item.type,
        subType: item.subType,
        agents: item.agents.length ? item.agents : [{ agentId: null, name: "No agents available", number: "N/A" }]
      }));
      setSiteNumberData(groupedData);
    } catch (err) {
      console.error('Error fetching site number data:', err);

    }
  };

  useEffect(() => {
    handleView();
  }, []);

  const viewAgent = async () => {
    try {
      const res = await ResponseFromServer('sitenumber/sitenumber-list', `agent`);
      setAgents(res.data);
    } catch (err) {
      console.error('Error fetching agents:', err);

    }
  };

  const updateAgent = async () => {
  if (selectedAgents.length === 0) {
    alert('Please select at least one agent.');
    return;
  }

  const payload = {
    type: agent.type,
    subType: agent.subType,
    agentIds: selectedAgents.map(Number),
  };

  try {
    const res = await sendData('sitenumber/sitenumber-list', `agent`, payload);
    const updatedAgents = agents.filter(a => selectedAgents.includes(a.agentId));
    const updatedAgentData = updatedAgents.map(a => ({
      agentId: a.agentId,
      name: a.name,
      number: a.number,
    }));
    setSiteNumberData(prevData =>
      prevData.map(item =>
        item.type === agent.type && item.subType === agent.subType
          ? { ...item, agents: updatedAgentData }
          : item
      )
    );
    setAgent(res.data);
    handleClose();
  } catch (err) {
    console.error('Error updating agent:', err);
  }
};

  const columns = [
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'SubType',
      accessor: 'subType',
      Cell: ({ row }) => (
        <span>{row.original.subType || 'N/A'}</span>
      ),
    },
    {
      Header: 'Agent',
      accessor: 'subtype',
      Cell: ({ row }) => {
        const agents = row.original.agents.map(agent => `${agent.name} (${agent.number})`);
        const midpoint = Math.ceil(agents.length / 2);
        return (
          <span>
            {agents.length > 0 ? (
              <>
                <div>{agents.slice(0, midpoint).join(", ")}</div>
                <div>{agents.slice(midpoint).join(", ")}</div>
              </>
            ) : (
              'N/A'
            )}
          </span>
        );
      },
    },
    {
      Header: 'Update Agent',
      accessor: 'agentId',
      Cell: ({ row }) => (
        <span style={{ color: "blue", cursor: "pointer" }} onClick={() => handleShow(row.original)}>
          Update
        </span>
      ),
    },
  ];

  return (
    <>
      <Modal size="md" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="type">
              <Form.Label>Type: <span className="text-primary">{agent.type}</span></Form.Label>
            </Form.Group>
            <Form.Group className="mb-3" controlId="subType">
              <Form.Label>SubType: <span className="text-primary">{agent.subType}</span></Form.Label>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Available Agents</Form.Label>
              {agents.map((a) => (
                <Form.Check
                  key={a.agentId}
                  type="checkbox"
                  id={`agent-${a.agentId}`}
                  label={`${a.name} - ${a.number}`}
                  value={a.agentId}
                  onChange={handleCheckboxChange}
                  checked={selectedAgents.includes(Number(a.agentId))}
                />
              ))}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={updateAgent}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <AdvanceTableWrapper columns={columns} data={siteNumberData} sortable pagination perPage={500}>
        <Card className="mb-3">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Site Numbers</h5>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden',
              }}
            />
          </Card.Body>
          <Card.Footer>

          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default SiteNumber;
