import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import React, { useState,useEffect } from 'react';
import { Card, Dropdown,Row,Col,Form,Button ,ListGroup,Table } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import RequirementHeader from './RequirementHeader';
import { ResponseFromServer } from 'helpers/utils';




const RequirementList = () =>  {







  const [requirementFilter ,setRequirementFilter] =useState([])

   const allSizes = [  "xs","s","m", "l", "xl", "2xl", "3xl","4xl","5xl"];

  return (

    <div>
      <RequirementHeader setRequirementFilter={setRequirementFilter}/>
      {requirementFilter.map((product, idx) => (
          <Card key={idx} className="mb-4">
            <Card.Body>
              <Card.Title>{product.productName}</Card.Title>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Color Name</th>
                    {allSizes.map((size, sizeIdx) => (
                      <th key={sizeIdx}>{size.toUpperCase()}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {product.colorDetails.map((color, colorIdx) => (
                    <tr key={colorIdx}>
                      <td>{color.colorName}(  {color.sizeQuantityList.reduce((total, item) => total + (item.count || 0), 0)})</td>
                      {allSizes.map((size) => {
                        const sizeInfo = color.sizeQuantityList.find(item => item.size === size);
                        return (
                          <td key={size}>
                            {sizeInfo ? sizeInfo.count : 0}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        ))}
      </div>
  );
};

export default RequirementList;
