import react,{useEffect,useState,useRef} from "react";
import { Col } from 'react-bootstrap';
import CalculateImageDimension from 'helpers/canvasReRender';

const createDesigns = ({item}) => {
  const parentNode =  useRef(null);
  const [imageIndex, setImageIndex] = useState(0);
  

  return (

    <Col className="position-relative my-4 p-2" style={{ margin: 0, padding: 0 }} xs={4} md={4}>
    {item.design?.canvas && (
      <CalculateImageDimension
        key={imageIndex}
        imageUrl={item.images[imageIndex].imageUrl}
        node={parentNode}
        canx={item.design.canvas[imageIndex]}
      />
    )}
      <img
        src={item.images[imageIndex].imageUrl}
        alt="Logitech G305 Gaming Mouse"
        className="w-100  rounded"
        ref={parentNode}
      />
      <div className="btn-group mt-2 ms-4" role="group" aria-label="Basic outlined example">
        {item.sides.map((sideName, idx) => (
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => setImageIndex(idx)}
            key={idx}
          >
            {sideName.title}
          </button>
        ))}
      </div>
    </Col>
  );
};

export default createDesigns;
