  import React, { useState, useEffect } from "react";
  import { Card, Col, Row ,Button,Modal,Form} from 'react-bootstrap';
  import Flex from 'components/common/Flex';
  import IconButton from 'components/common/IconButton';
  import classNames from 'classnames';
  import PropTypes from 'prop-types';
  import axios from 'axios';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
  import { Link } from 'react-router-dom';
  import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
  import AdvanceTable from 'components/common/advance-table/AdvanceTable';
  import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
  import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
  import { ResponseFromServer } from 'helpers/utils';
  import DateFilter from './leadfillter';
  import Badge from 'components/common/SoftBadge';
  import Editlead from './editlead';
  import ViewLeadInfo from './viewleadinfo';

  const LeadHistory = (setRedirect, setRedirectUrl) => {
  const [lgShow, setLgShow] = useState(false);
  const [leadList,setLeadList] = useState([]);
  const [date, setDate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentField, setCurrentField] = useState('');
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [images,setImages] = useState([]);

  const [tempInputValues, setTempInputValues] = useState({
    minQuantity: '',
    maxQuantity: '',
    possibility: '',
    status: '',
    followUpDate: '',
 });

 const [inputValues, setInputValues] = useState({
 minQuantity: '',
 maxQuantity: '',
 possibility: '',
 status: '',
 followUpDate: '',
});




  const getUserData = () => {
  const params = {
    minQuantity: inputValues.minQuantity,
    maxQuantity: inputValues.maxQuantity,
    possibility: inputValues.possibility,
    status: inputValues.status,
    followUpDate: inputValues.followUpDate,
  };

  const filteredParams = Object.keys(params)
    .filter((key) => params[key] != null && params[key] !== '')
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');

  const url = filteredParams ? `lead?${filteredParams}` : 'lead';

  ResponseFromServer('leadinfo/leaddetails', url)
    .then((res) => {
      if (res.data && res.data.length > 0) {
        setLeadList(res.data);
      } else {
        console.error('No content found in the response');
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};
  useEffect(getUserData,[])

  const handleShow = (field) => {
    setCurrentField(field);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentField('');
  };

  const saveChanges = () => {
    if (['minQuantity', 'maxQuantity', 'possibility', 'status', 'followUpDate'].includes(currentField)) {
      setInputValues((prevValues) => ({
        ...prevValues,
        [currentField]: tempInputValues[currentField],
      }));
    }

    setShowModal(false);
    setTempInputValues({
      minQuantity: '',
      maxQuantity: '',
      possibility: '',
      status: '',
      followUpDate: '',
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTempInputValues((prevValues) => ({
      ...prevValues,
      [name]: value, // Update the specific field that has changed
    }));
  };
  

   const clearMinQuantity = () => setInputValues((prev) => ({ ...prev, minQuantity: '' }));
   const clearMaxQuantity = () => setInputValues((prev) => ({ ...prev, maxQuantity: '' }));
   const clearPossibility = () => setInputValues((prev) => ({ ...prev, possibility: '' }));
   const clearStatus = () => setInputValues((prev) => ({ ...prev, status: '' }));
   const clearFollowUpDate = () => setInputValues((prev) => ({ ...prev, followUpDate: '' }));

  const renderInputField = () => {
    switch (currentField) {
      case 'minQuantity':
        return (
          <Form.Group controlId="minQuantity">
            <Form.Label>Min Quantity</Form.Label>
            <Form.Control
              size="sm"
              type="number"
              name="minQuantity"
              placeholder="Enter Min Quantity"
              value={tempInputValues.minQuantity}
              onChange={handleInputChange}
            />
          </Form.Group>
        );
      case 'maxQuantity':
        return (
          <Form.Group controlId="maxQuantity">
            <Form.Label>Max Quantity</Form.Label>
            <Form.Control
              size="sm"
              type="number"
              name="maxQuantity"
              placeholder="Enter Max Quantity"
              value={tempInputValues.maxQuantity}
              onChange={handleInputChange}
            />
          </Form.Group>
        );
      case 'possibility':
        return (
          <Form.Group controlId="possibility">
            <Form.Label>Possibility</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="possibility"
              placeholder="Enter Possibility"
              value={tempInputValues.possibility}
              onChange={handleInputChange}
            />
          </Form.Group>
        );
      case 'status':
        return (
          <Form.Group controlId="status">
            <Form.Label>Status</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="status"
              placeholder="Enter Status"
              value={tempInputValues.status}
              onChange={handleInputChange}
            />
          </Form.Group>
        );
      case 'followUpDate':
        return (
          <Form.Group controlId="followUpDate">
            <Form.Label>Follow-Up Date</Form.Label>
            <Form.Control
              size="sm"
              type="date"
              name="followUpDate"
              value={tempInputValues.followUpDate}
              onChange={handleInputChange}
            />
          </Form.Group>
        );

      default:
        return null;
    }
  };





  const columns =  [
    {
      accessor: 'leadId',
      Header: 'Name',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: ({ row }) => (
        <strong>
          <Link
            className="text-info"
            onClick={() => handleView(row.original.leadId)}
          >
            {row.original.name}
          </Link>
          <div>
              {row.original.status}
          </div>
        </strong>
      )
    },
  {
    accessor: 'city',
    Header: 'Location',
    headerProps: {
    className: 'text-start'
    },
    cellProps: {
    className: 'text-start'
  },Cell: ({ row }) => (
    <div>
    <div>
        {row.original.city} {row.original.State}
    </div>
      <div>
          {row.original.email}
      </div>
      <div>
          {row.original.phoneNumber}
      </div>
    </div>
  )
  },
  {
    accessor: 'followUpDate',
    Header: 'FollowUp Date',
    headerProps: {
    className: 'text-start'
    },
    cellProps: {
    className: 'text-start'
    }
  },
  {
    accessor: 'leadRequirements',
    Header: 'Lead Requirements',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    },
    Cell: ({ row }) => (
      row.original.leadRequirements && row.original.leadRequirements.length > 0 ? (
        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }} >
          {row.original.leadRequirements.map((requirement, index) => (
            <li key={index}>
              {requirement.productName} (Qty: {requirement.quantity})

            </li>
          ))}
        </ul>
      ) : (
        'No Requirements'
      )
    )
  },
  {
    accessor: 'leadSource',
    Header: 'Lead Source',
    headerProps: {
    className: 'text-start'
    },
    cellProps: {
    className: 'text-start'
    }
  },
  {
    accessor: 'leadChannel',
    Header: 'Lead Channel',
    headerProps: {
    className: 'text-start'
    },
    cellProps: {
    className: 'text-start'
    }
  },

  ];

  const handleView = (id,image) => {

      setLgShow(!lgShow);
      setImages(image)
         ResponseFromServer('leadinfo/leaddetails',`lead/${id}`).then((res)=>setSelectedItemData(res.data))

  };



  return (
    <>

  <Card>
  <Card.Header className="bg-light">
  <Row className="align-items-center">
  <Col>
    <h5 className="mb-0">Leads Details</h5>
  </Col>
  </Row>
  </Card.Header>
  <Card.Body>
  <AdvanceTableWrapper
  columns={columns}
  data={leadList}
  sortable
  pagination
  perPage={100}
  >
  <Row className="flex-between-center mt-4 mb-4">
    <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
      <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Lead Filter</h5>
    </Col>
    <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
      <div id="orders-actions">
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="id-badge"
          transform="shrink-3"
          className="mx-2"
          onClick={() => handleShow('minQuantity')}
        >
          <span className="d-none d-sm-inline-block ms-1">Min Quantity</span>
        </IconButton>
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="id-badge"
          transform="shrink-3"

          onClick={() => handleShow('maxQuantity')}
        >
          <span className="d-none d-sm-inline-block ms-1">Max Quantity</span>
        </IconButton>

        <IconButton
          variant="falcon-default"
          size="sm"
          icon="truck"
          transform="shrink-3"
          className="mx-2"
          onClick={() => handleShow('possibility')}
        >
          <span className="d-none d-sm-inline-block ms-1">Possibility</span>
        </IconButton>
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="truck"
          transform="shrink-3"
          onClick={() => handleShow('status')}
        >
          <span className="d-none d-sm-inline-block ms-1">Status</span>
        </IconButton>
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="truck"
          transform="shrink-3"
          className="mx-2"
          onClick={() => handleShow('followUpDate')}
        >
          <span className="d-none d-sm-inline-block ms-1">Follow Up Date</span>
        </IconButton>
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="search" // Search
          transform="shrink-3"
          className=" bg-success text-white "
          onClick={() => getUserData()}
        >
          <span className="d-none d-sm-inline-block ms-1">Search</span>
        </IconButton>
      </div>
    </Col>
  </Row>

  <Row className="mb-2">

        <Col xs="auto mt-2 p-0">
          <Flex justifyContent="start" alignItems="center" flexWrap="wrap">
            <div>
              {inputValues.minQuantity && (
                <Badge bg="info" pill className="fs--2 mx-1">
                  Min Quantity: <span className ="text-dark">{inputValues.minQuantity}</span>
                  <Button variant="link" className="text-danger p-0 ms-2" size="sm" onClick={clearMinQuantity}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </Button>
                </Badge>
              )}
            </div>
          </Flex>
        </Col>
        <Col xs="auto mt-2 p-0">
          <Flex justifyContent="start" alignItems="center" flexWrap="wrap">
            <div>
              {inputValues.maxQuantity && (
                <Badge bg="info" pill className="fs--2 mx-1">
                  Max Quantity: <span className ="text-dark">{inputValues.maxQuantity}</span>
                  <Button variant="link" className="text-danger p-0 ms-2" size="sm" onClick={clearMaxQuantity}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </Button>
                </Badge>
              )}
            </div>
          </Flex>
        </Col>
        <Col xs="auto mt-2 p-0">
          <Flex justifyContent="start" alignItems="center" flexWrap="wrap">
            <div>
              {inputValues.possibility && (
                <Badge bg="info" pill className="fs--2 mx-1">
                  Possibility: <span className ="text-dark">{inputValues.possibility}</span>
                  <Button variant="link" className="text-danger p-0 ms-2" size="sm" onClick={clearPossibility}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </Button>
                </Badge>
              )}
            </div>
          </Flex>
        </Col>
        <Col xs="auto mt-2 p-0">
          <Flex justifyContent="start" alignItems="center" flexWrap="wrap">
            <div>
              {inputValues.status && (
                <Badge bg="info" pill className="fs--2 mx-1">
                  Status: <span className ="text-dark">{inputValues.status}</span>
                  <Button variant="link" className="text-danger p-0 ms-2" size="sm" onClick={clearStatus}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </Button>
                </Badge>
              )}
            </div>
          </Flex>
        </Col>
        <Col xs="auto mt-2 p-0">
          <Flex justifyContent="start" alignItems="center" flexWrap="wrap">
            <div>
              {inputValues.followUpDate && (
                <Badge bg="info" pill className="fs--2 mx-1">
                  FollowUpDate: <span className ="text-dark">{inputValues.followUpDate}</span>
                  <Button variant="link" className="text-danger p-0 ms-2" size="sm" onClick={clearFollowUpDate}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </Button>
                </Badge>
              )}
            </div>
          </Flex>
        </Col>

   </Row>

   <AdvanceTable
     table
     headerClassName="bg-200 text-900 text-nowrap align-middle"
     rowClassName="align-middle white-space-nowrap"
     tableProps={{
       bordered: true,
       striped: true,
       className: 'fs--1 mb-0 overflow-hidden'
     }}
   />

   <div className="mt-3">
          <AdvanceTablePagination
            table
  />
        </div>

  </AdvanceTableWrapper>

    <ViewLeadInfo lgShow={lgShow} setLgShow={setLgShow}  data={selectedItemData}  images={images} />

  </Card.Body>


  </Card>

  <Modal size="md" show={showModal} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Edit {currentField}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{renderInputField()}</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      <Button variant="primary" onClick={saveChanges}>
        Save Changes
      </Button>
    </Modal.Footer>
  </Modal>
  </>

  );
  }


  export default LeadHistory;
