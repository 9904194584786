import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import { AuthProvider } from './context/auth';
import './curveTextClass';
import {fabric} from 'fabric';
import FontFaceObserver from 'fontfaceobserver';

const App = () => {
  React.useEffect(()=>{
    fabric.CurvedText.fromObject = async function(object, callback) {
        let combo = `${object.fontFamily}-${object.fontWeight || 'normal'}-${object.fontStyle || 'normal'}`;
        let [fontFamily, fWeight, fontStyle] = combo.split('-');
        const fontObserver = new FontFaceObserver(fontFamily, {
          weight: fWeight,
          style: fontStyle
        });
       await fontObserver.load(null, 1000)
    	 return fabric.Object._fromObject('CurvedText', object, callback,'curved-text');
      }



  },[])

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <AuthProvider>
        <Layout />
      </AuthProvider>
    </Router>
  );
};

export default App;
