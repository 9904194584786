import react,{useEffect,useState,useRef} from "react";
import {ResponseFromServer,sendData,PutToServer} from 'helpers/utils';
import { Card,Row,Col,DropdownButton,Dropdown} from 'react-bootstrap';
import CalculateImageDimension from 'helpers/canvasReRender';
import OrderCard from './orderCard';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
const PendingList = ()=>{
  const [activePage,setActivePage] = useState(0)
  const [orderStatus,setOrderStatus] = useState('pending')
  const [ordeData,setOrderData] = useState([])
  const parentNode =  useRef(null);
  const [pagesCount,setPageCount] = useState(null)
  const [loading,setLoading] = useState(false)

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const yyyy = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12; // Convert 0 to 12 for midnight

    return `${dd}-${mm}-${yyyy} ${hours}:${minutes} ${ampm}`;
}



  const handleSelect = (e)=>{
    setOrderStatus(e)
  }

  const fetchData = async ()=>{
      await ResponseFromServer('pending/pending-list',`order/details?status=${orderStatus}&page=${activePage}&size=10`).then((res)=>{
          setOrderData(res.data)
          setPageCount(res.data.totalPages)
      })
  }



  const createDesigns =(item)=>{

    let hasDesign = false;
     for(let i =0;i < Object.keys(item.design.canvas).length;i++){
       if(item.design.canvas[i].objects.length > 0){
         hasDesign = true;
         break;
       }
     }

     if(hasDesign){
       return (item.images.map((imageData,idx)=>{
         if(item.design.canvas[idx].objects.length !== 0){
         return renderImage(imageData,idx,item.design.canvas[idx])
       }
     }))
     } else {
        return renderImage(item.images[0],0,item.design.canvas[0])
     }

  }


  const gotoPage = async (index)=>{
    setLoading(true)
    setActivePage(index)
    await ResponseFromServer('pending/pending-list',`order/details?status=${orderStatus}&page=${index}&size=10`).then((res)=>{
        setOrderData(res.data)
        setPageCount(res.data.totalPages)
        setLoading(false)
    })

  }

  const renderImage = (imageData,idx,canvas)=>{
      return(
        <Col className="position-relative" style={{margin:0,padding:0}} xs={6} md={6} key={idx}>
          <CalculateImageDimension   imageUrl={imageData.imageUrl} node={parentNode} canx={canvas} />
          <img
            src={imageData.imageUrl}
            alt="Logitech G305 Gaming Mouse"
            className="h-100 w-100 fit-cover rounded"
            ref={parentNode}
          />
        </Col>
      )

  }


  useEffect(fetchData,[orderStatus])

  return(<>
    <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Previous Orders</h5>
            </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <DropdownButton
              id="dropdown-basic-button"
              title={orderStatus}
              onSelect={handleSelect}
              className="text-uppercase"
            >
            {orderStatus !== 'pending'?(<Dropdown.Item eventKey="pending">Pending</Dropdown.Item>):(<Dropdown.Item eventKey="saved">Saved</Dropdown.Item>)}
            </DropdownButton>
            {!loading?(ordeData?.content?.map((item,idx)=>(
                <Row key={idx} className="mt-4 shadow-sm p-3 mb-3 bg-body rounded">
                  <Col sm={12} md={12}>
                    <Row className="h-100">
                      <Col  lg={9}>
                        <h5 className="mt-3 mt-sm-0">
                          <Row>
                            {createDesigns(item)}
                          </Row>
                          <div className="d-flex mt-3" style={{justifyContent:'space-between'}}>
                            <h6><strong>First visited:</strong> <br/>{formatTimestamp(item?.design.firstVisitTime) ?? "N/A"}</h6>
                            <h6><strong>Design started:</strong><br/> {formatTimestamp(item?.design.createdTime) ?? "N/A"}</h6>
                            <h6><strong>Design updated:</strong><br/> {formatTimestamp(item?.design.updatedTime) ?? "N/A"}</h6>
                            <h6><strong>Order started:</strong><br/> {formatTimestamp(item?.createdTime) ?? "N/A"}</h6>
                            <h6><strong>Order updated:</strong><br/> {formatTimestamp(item?.updatedTime) ?? "N/A"}</h6>
                          </div>

                        </h5>
                      </Col>
                      <Col lg={3}>
                        <OrderCard item={item} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))):(null)}

          </Card.Body>

      </Card>
      <AdvanceTablePagination table pageCount={pagesCount} gotoPage={gotoPage}  pageIndex={activePage}/>

    </>)
}

export default PendingList;
