import React, { useState, useEffect,useCallback } from "react";
import { Modal, Row, Col, Card, Form,Image,Button } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import IconButton from 'components/common/IconButton';
import classNames from 'classnames';
import Axios from "axios";
import { PutToServer,PutToImage } from 'helpers/utils';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import CardDropdown from 'components/common/CardDropdown';
import { getSize } from 'helpers/utils';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';

const ViewLeadInfo = ({data,lgShow,setLgShow}) => {

  const [loader,setLoader] = useState(true);

   const leadId = data?.leadId;
  const initialData = data || {
    name: '',
    email: '',
    phoneNumber: '',
    altPhone: '',
    customerType: '',
    customerTypeName: '',
    address: '',
    companyName: '',
    gender: '',
    society: '',
    pinCode: '',
    state: '',
    city: '',
    gstDetails: '',
    notes: '',
    status: '',
    possibility: '',
    followUpDate: '',
    preferredTime: '',
    language: '',
    leadSource: '',
    leadChannel: '',
    leadRequirements: [],
  };

  useEffect(() => {
      setText(initialData);
    }, [data]);

  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialData);
 const [images, setImages] = useState([]);
 const [uploadedImages, setUploadedImages] = useState([]);
  const [uploading, setUploading] = useState(false);
   const toggleEdit = () => {
    if (isEditing) {
      let dataToSave = {
        name: text.name,
        email: text.email,
        phoneNumber: text.phoneNumber,
        altPhone:text.altPhone,
        customerType: text.customerType,
        customerTypeName:text.customerTypeName,
        address: text.address,
        pinCode: text.pinCode,
        state: text.state,
        city: text.city,
        gstDetails: text.gstDetails,
        notes: text.notes,
        status: text.status,
        possibility: text.possibility,
        followUpDate: text.followUpDate || null, // Initialized as null
        preferredTime: text.preferredTime,
        language: text.language,
        leadSource: text.leadSource,
        leadChannel: text.leadChannel,
        leadRequirements: text.leadRequirements,
      }

     PutToServer('/leadinfo/leaddetails', `/lead/${leadId}`, dataToSave).then((res)=>{
      console.log(res)
     })
    }
    setIsEditing(!isEditing);
  };


  const handleChange = (e, field) => {
    setText({ ...text, [field]: e.target.value });
    };

    const handleLeadRequirementChange = (e, index, field) => {
      const updatedRequirements = [...text.leadRequirements];
      updatedRequirements[index][field] = e.target.value;
      setText({...text, leadRequirements: updatedRequirements});
    };

    const handleSizeChange = (e, index, sizeIndex, field) => {
      const updatedRequirements = [...text.leadRequirements];
      updatedRequirements[index].sizeQuantityList[sizeIndex][field] = e.target.value;
      setText({...text, leadRequirements: updatedRequirements});
    };


const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setImages([...images, ...acceptedFiles]);
    }
  });

  const removeImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };


  const handleImageUpload = async () => {
    setUploading(true);
    setLoader(false);
    const formData = new FormData();
    images.forEach((image, index) => {
      formData.append(`files`, image);
    });


    try {

      const requirementId = data.leadRequirements.length > 0 ? data.leadRequirements[0].requirementId : null;

      if (!requirementId) {
        console.error('No requirement ID found');
        return;
      }

      const response = await PutToImage(`/lead/${leadId}/images/${requirementId}`, formData);

      if (response.status === 200) {
        setLoader(true)
       const data = await dataResponse.json();

        setText({ ...text, images: dataResponse.urls });
        setImages([]);


      } else {

        console.error('Image upload failed');
      }
    } catch (error) {
      console.error('Error occurred:', error);
    } finally {
      setUploading(false);
      setImages([]);
       toast.success('Images uploaded successfully');
    }
  };
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleRemoveImage = (idx) => {
    setSelectedImageIndex(idx);
    setShowConfirmation(true);
  };

  const handleConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
  };


  return (
    <>

      {data === null ? (null) : (
        <Modal
          size="xl"
          show={lgShow}
          onHide={() =>setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg">
              Lead Details
            </Modal.Title>
            <FalconCloseButton onClick={() => setLgShow(false)} />
          </Modal.Header>
          <Modal.Body>
            <Card className="mb-3">
              <Card.Header>
                <Row className="align-items-center">
                  <Col>
                    <h5 className="mb-0">{text.name}</h5>
                  </Col>
                  <Col xs="auto">
                    <IconButton
                      iconClassName="fs--2 me-1"
                      variant="falcon-default"
                      size="sm"
                      icon="close"
                      onClick={toggleEdit}
                    >
                      {isEditing ? 'Save' : 'Edit'}
                    </IconButton>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="bg-light border-top">

                <Row>
                  <Col >
                    <h6 className="fw-semi-bold ls mb-3 text-uppercase">Personal Information</h6>

                    {isEditing ? (
                      <>
                      <Row>
                        <Col>
                          {/* Name Field */}
                          <Row>
                            <Col xs={5} sm={4}>
                              <p className="fw-semi-bold ls mb-3">Name</p>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                value={text.name}
                                onChange={(e) => handleChange(e, 'name')}
                              />
                            </Col>
                          </Row>

                          {/* Email Field */}
                          <Row>
                            <Col xs={5} sm={4}>
                              <p className="fw-semi-bold ls mb-3">Email</p>
                            </Col>
                            <Col sm={8}>
                              <Form.Control
                                type="text"
                                value={text.email}
                                onChange={(e) => handleChange(e, 'email')}
                              />
                            </Col>
                          </Row>

                          {/* Phone Number Field */}
                          <Row>
                            <Col xs={5} sm={4}>
                              <p className="fw-semi-bold ls mb-3">Phone</p>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                value={text.phoneNumber}
                                onChange={(e) => handleChange(e, 'phoneNumber')}
                              />
                            </Col>
                          </Row>

                          {/* Alternate Phone Number Field */}
                          <Row>
                            <Col xs={5} sm={4}>
                              <p className="fw-semi-bold ls mb-3">Alt Phone</p>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                value={text.altPhoneNumber || ''}
                                onChange={(e) => handleChange(e, 'altPhoneNumber')}
                              />
                            </Col>
                          </Row>

                          {/* Address Field */}
                          <Row>
                            <Col xs={5} sm={4}>
                              <p className="fw-semi-bold ls mb-3">Address</p>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                value={text.address}
                                onChange={(e) => handleChange(e, 'address')}
                              />
                            </Col>
                          </Row>

                          {/* City Field */}
                          <Row>
                            <Col xs={5} sm={4}>
                              <p className="fw-semi-bold ls mb-3">City</p>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                value={text.city}
                                onChange={(e) => handleChange(e, 'city')}
                              />
                            </Col>
                          </Row>

                          {/* State Field */}
                          <Row>
                            <Col xs={5} sm={4}>
                              <p className="fw-semi-bold ls mb-3">State</p>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                value={text.state}
                                onChange={(e) => handleChange(e, 'state')}
                              />
                            </Col>
                          </Row>

                          {/* Pin Code Field */}
                          <Row>
                            <Col xs={5} sm={4}>
                              <p className="fw-semi-bold ls mb-3">Pin Code</p>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                value={text.pinCode}
                                onChange={(e) => handleChange(e, 'pinCode')}
                              />
                            </Col>
                          </Row>

                          {/* GST Details Field */}
                          <Row>
                            <Col xs={5} sm={4}>
                              <p className="fw-semi-bold ls mb-3">GST Details</p>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                value={text.gstDetails || ''}
                                onChange={(e) => handleChange(e, 'gstDetails')}
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col>

                        {/* Status Field */}
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Status</p>
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              value={text.status}
                              onChange={(e) => handleChange(e, 'status')}
                            />
                          </Col>
                        </Row>
                        {text.leadRequirements && text.leadRequirements.length > 0 && text.leadRequirements.map((requirement, index) => (
                         <div key={index}>
                           <Row className="mb-3">
                             <Col xs={5} sm={4}>
                               <p className="fw-semi-bold ls mb-1">Product Name</p>
                             </Col>
                             <Col>
                               <Form.Control
                                 type="text"
                                 value={requirement.productName}
                                 onChange={(e) => handleLeadRequirementChange(e, index, 'productName')}
                               />
                             </Col>
                           </Row>
                           <Row className="mb-3">
                             <Col xs={5} sm={4}>
                               <p className="fw-semi-bold ls mb-1">Quantity</p>
                             </Col>
                             <Col>
                               <Form.Control
                                 type="text"
                                 value={requirement.quantity}
                                 onChange={(e) => handleLeadRequirementChange(e, index, 'quantity')}
                               />
                             </Col>
                           </Row>
                           {requirement.sizeQuantityList && requirement.sizeQuantityList.length > 0 && (
                             <>
                               <Row className="mb-3">
                                 <Col xs={5} sm={4}>
                                   <p className="fw-semi-bold ls mb-1">Size</p>
                                 </Col>
                                 <Col>
                                   {requirement.sizeQuantityList.map((size, sizeIndex) => (
                                     <Row key={sizeIndex} className="mb-2">
                                       <Col xs={6}>
                                         <Form.Control
                                           type="text"
                                           value={size.size}
                                           onChange={(e) => handleSizeChange(e, index, sizeIndex, 'size')}
                                         />
                                       </Col>
                                       <Col xs={6}>
                                         <Form.Control
                                           type="number"
                                           value={size.count}
                                           onChange={(e) => handleSizeChange(e, index, sizeIndex, 'count')}
                                         />
                                       </Col>
                                     </Row>
                                   ))}
                                 </Col>
                               </Row>
                             </>
                           )}

                        {/* Possibility Field */}
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Possibility</p>
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              value={text.possibility}
                              onChange={(e) => handleChange(e, 'possibility')}
                            />
                          </Col>
                        </Row>
                          {/* Lead Source Field */}
                          <Row>
                            <Col xs={5} sm={4}>
                              <p className="fw-semi-bold ls mb-3">Lead Source</p>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                value={text.leadSource}
                                onChange={(e) => handleChange(e, 'leadSource')}
                              />
                            </Col>
                          </Row>

                          {/* Lead Channel Field */}
                          <Row>

                            <Col xs={5} sm={4}>
                              <p className="fw-semi-bold ls mb-3">Lead Channel</p>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                value={text.leadChannel}
                                onChange={(e) => handleChange(e, 'leadChannel')}
                              />
                            </Col>
                          </Row>

                          {/* Language Field */}
                          <Row>
                            <Col xs={5} sm={4}>
                              <p className="fw-semi-bold ls mb-3">Language</p>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                value={text.language}
                                onChange={(e) => handleChange(e, 'language')}
                              />
                            </Col>
                          </Row>

                          {/* Preferred Time Field */}
                          <Row>
                            <Col xs={5} sm={4}>
                              <p className="fw-semi-bold ls mb-3">Preferred Time</p>
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                value={text.preferredTime || ''}
                                onChange={(e) => handleChange(e, 'preferredTime')}
                              />
                            </Col>
                          </Row>

                          {/* Follow-Up Date Field */}
                          <Row>
                            <Col xs={5} sm={4}>
                              <p className="fw-semi-bold ls mb-3">Follow-Up Date</p>
                            </Col>
                            <Col>
                              <Form.Control
                                type="date"
                                value={text.followUpDate || ''}
                                onChange={(e) => handleChange(e, 'followUpDate')}
                              />
                            </Col>
                          </Row>

                          {/* Notes Field */}
                          <Row>
                            <Col xs={5} sm={4}>
                              <p className="fw-semi-bold ls mb-3">Notes</p>
                            </Col>
                            <Col>
                              <Form.Control
                                as="textarea"
                                value={text.notes || ''}
                                onChange={(e) => handleChange(e, 'notes')}
                              />
                            </Col>
                          </Row>

                             <Row className="mb-3">
                               <Col xs={5} sm={4}>
                                 <p className="fw-semi-bold ls mb-1">Quotations</p>
                               </Col>
                               <Col>
                                 <Form.Control
                                   type="text"
                                   value={requirement.quotation || ""}
                                   onChange={(e) => handleLeadRequirementChange(e, index, 'quotation')}
                                 />
                               </Col>
                             </Row>
                             <Row className="mb-3">
                               <Col xs={5} sm={4}>
                                 <p className="fw-semi-bold ls mb-1">Design URL</p>
                               </Col>
                               <Col>
                                 <Form.Control
                                   type="text"
                                   value={requirement.designUrl || ""}
                                   onChange={(e) => handleLeadRequirementChange(e, index, 'designUrl')}
                                 />
                               </Col>
                             </Row>
                           </div>
                         ))}

                        </Col>
                      </Row>

                      </>
                    ) : (
                      <>
                      <Row>
                      <Col>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Name</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{data.name}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Email</p>
                          </Col>
                          <Col sm={8}>
                            <p className="fw-semi-bold mb-1">{data.email}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Phone</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{data.phoneNumber}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Alt Phone</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{data.altPhoneNumber || 'N/A'}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Address</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{data.address}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">City</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{data.city}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">State</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{data.state}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Pin Code</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{data.pinCode}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">GST Details</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{data.gstDetails || 'N/A'}</p>
                          </Col>
                        </Row>
                      </Col>

                      <Col>

                      {data.leadRequirements && data.leadRequirements.length > 0 && (
                          <Row>
                            <Col>
                              {data.leadRequirements.map((requirement, index) => (
                                <div key={index}>
                                  {/* Product Name Row */}
                                  <Row className="mb-3">
                                    <Col xs={5} sm={4}>
                                      <p className="fw-semi-bold ls mb-1">Product Name</p>
                                    </Col>
                                    <Col>
                                      <p className="fw-semi-bold mb-1">{requirement.productName}</p>
                                    </Col>
                                  </Row>

                                  {/* Quantity Row */}
                                  <Row className="mb-3">
                                    <Col xs={5} sm={4}>
                                      <p className="fw-semi-bold ls mb-1">Quantity</p>
                                    </Col>
                                    <Col>
                                      <p className="fw-semi-bold mb-1">{requirement.quantity}</p>
                                    </Col>
                                  </Row>

                                  {/* Size Quantity Row */}
                                  {requirement.sizeQuantityList && requirement.sizeQuantityList.length > 0 && (
                                    <Row className="mb-3">
                                      <Col xs={5} sm={4}>
                                        <p className="fw-semi-bold ls mb-1">Size</p>
                                      </Col>
                                      <Col>
                                        <p className="fw-semi-bold mb-1">
                                          {requirement.sizeQuantityList.map((size, sizeIndex) => (
                                            <span key={sizeIndex}>
                                              {size.size} ({size.count})
                                              {sizeIndex < requirement.sizeQuantityList.length - 1 && ", "}
                                            </span>
                                          ))}
                                        </p>
                                      </Col>
                                    </Row>
                                  )}
                                </div>
                              ))}
                            </Col>
                          </Row>
                        )}

                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Customer Type</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{data.customerType || 'N/A'}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Customer Type Name</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{data.customerTypeName || 'N/A'}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Lead Status</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{data.status}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Possibility</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{data.possibility}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Follow-Up Date</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{data.followUpDate || 'N/A'}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Preferred Time</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{data.preferredTime || 'N/A'}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Lead Source</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{data.leadSource}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5} sm={4}>
                            <p className="fw-semi-bold ls mb-3">Lead Channel</p>
                          </Col>
                          <Col>
                            <p className="fw-semi-bold mb-1">{data.leadChannel}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                      </>
                    )}
                  </Col>

        </Row>

<Row>
<Col>
<Row>
  <Col>
    <h6 className="fw-semi-bold ls mb-3 text-uppercase">Images</h6>
  </Col>
  {loader ? (
    data.leadRequirements.map((requirement) => (
      <Row key={requirement.requirementId}>
        {requirement.images && requirement.images.length > 0 ? (
          requirement.images.map((image) => (
            <Col key={image.imageId} className="g-3">
              <Card className="bg-dark text-white light" style={{ width: '15rem' }}>
                <Card.ImgOverlay className="d-flex flex-row justify-content-end">
                  {/* Close button or remove functionality can go here */}
                </Card.ImgOverlay>
                <Card.Img
                  src={image.imageDesktop}
                  rounded
                  className="me-3 d-none d-md-block"
                  height="150"
                  alt={`Product image ${image.imageId}`}
                />
              </Card>
            </Col>
          ))
        ) : (
          <Col>
            <p>No images available for this product.</p>
          </Col>
        )}
      </Row>
    ))
  ) : (
    <Col>
      <span>Loading...</span>
    </Col>
  )}
</Row>


<Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Removal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to remove this image?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelConfirmation}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmation}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

</Col>
<Col>
<Row>
<div {...getRootProps({ className: 'dropzone-area py-6' })} >
        <input {...getInputProps()} />
        <p>Drag 'n' drop some images here, or click to select images</p>
      </div>
      <div>
        {images.map((file, index) => (
          <Flex
alignItems="center"
className="py-3 border-bottom btn-reveal-trigger"
key={file.name}
>
            <img
              src={URL.createObjectURL(file)}
              alt={file.name}
              width={100}
              height={100}
            />
            <Flex justifyContent="between" alignItems="center" className="ms-3 flex-1">
            <div>
<h6>{file.name}</h6>
<Flex className="position-relative" alignItems="center">
            <p className="mb-0 fs--1 text-400 line-height-1">
  <strong>
    {getSize(file.size)}
  </strong>
</p>
</Flex>
</div>
</Flex>

<CardDropdown>
<div className="py-2">
<Dropdown.Item className="text-danger" onClick={() => removeImage(index)}>
Remove
</Dropdown.Item>
</div>
</CardDropdown>
</Flex>

        ))}
      </div>
      <button onClick={handleImageUpload} disabled={images.length === 0 || uploading}>
        {uploading ? 'Uploading...' : 'Upload Images'}
      </button>
      {uploadedImages.length > 0 && (
        <div>
          <h2>Uploaded Images:</h2>
          <ul>
            {uploadedImages.map((url, index) => (
              <li key={index}>
                <img src={url} alt={`Uploaded Image ${index}`} width={100} />
              </li>
            ))}
          </ul>
        </div>
      )}
      </Row>
</Col>

</Row>


              </Card.Body>
              <Card.Footer className="border-top text-end">

              </Card.Footer>
            </Card>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default ViewLeadInfo;
