import React, { useState, useEffect } from 'react';
import { Tab ,Nav,Button,Table,Form,Row, Col, Card, Carousel, Badge,Modal } from 'react-bootstrap';
import { ResponseFromServer,PutToServer,sendData,sendImage } from 'helpers/utils';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import StarRating from 'components/common/StarRating';
import IconButton from 'components/common/IconButton';
import { useSearchParams } from 'react-router-dom';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';



const ProductName = () => {
  const [productDetail, setProductDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const [carouselImages, setCarouselImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showColorModal, setShowColorModal] = useState(false);
  const [showItemModal, setShowItemModal] = useState(false);
  const [numberOfColors, setNumberOfColors] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProduct, setEditedProduct] = useState({});
   const [selectedImage, setSelectedImage] = useState(null);




  const [formData, setFormData] = useState({
    name: '',
    status: 'ACTIVE',
    colorOptions:'',
    offerPrice: '',
    basePrice:'',
    clientRating: '',
    fabric: '',
    gsm: '',
    mrp: '',
    ratingCount: '',
    moq: '',
    availableSizes: [],
    productDescription: [],
    customizeOptions: [],
    moreInfo: [],
  });
  const [colorData, setColorData] = useState({
    name: '',
    status: 'ACTIVE',
    color:[],
    colorName: '',
    colorIndex: '',
    shortName: '',
  });


  const [searchParams] = useSearchParams();
 const pageName = searchParams.get('productName');

 useEffect(() => {
    if (pageName) {
      getProductDetails(pageName);
    }
  }, [pageName]);

  const path = window.location.pathname;
  const search = window.location.search;
  const fullPath = path + search;

  const getProductDetails = (pageName) => {
      setLoading(true);
      ResponseFromServer(fullPath.replace("/", ""), `product?productName=${pageName}`)
        .then((res) => {
          if (res?.data?.productDetails) {
            setProductDetail(res.data.productDetails);
            const productOptions = res.data.productDetails.productOptions?.[0];
            const defaultImages = productOptions?.displayImages?.length
              ? productOptions.displayImages
              : productOptions?.images || [];
            setCarouselImages(defaultImages);

            const colorOptions =
              res.data.productDetails.productOptions?.map((option) => option.name) || [];
            const uniqueColors = new Set(colorOptions.map((color) => color.toLowerCase())); // Case-insensitive comparison
            const numberOfColors = uniqueColors.size;
            setNumberOfColors(numberOfColors);
          } else {
            console.error('Product details not found in response');
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error('Error fetching product details:', err);
          setLoading(false);
        });
    };




  if (loading) {
    return <div>Loading...</div>; // Add a loader or message while data is fetching
  }
  const handleShowColor = () => {
     setShowColorModal(true);
   };

   const handleCloseColor = () => setShowColorModal(false);

   const handleShowItem = (option) => {
      setSelectedProduct(option);
      setShowItemModal(true);
    };

    const handleCloseItem = () => setShowItemModal(false);




  // Handler to set carousel images based on selected color option
  const handleColorClick = (option) => {
    const selectedImages = option.displayImages?.length ? option.displayImages : option.images;
    setCarouselImages(selectedImages || []); // Set carousel images to selected color option images
  };

  const handleShow = () => {
     setFormData({
       name: productDetail.name,
       status: 'ACTIVE',
       colorOptions:numberOfColors,
       offerPrice: productDetail.offerPrice,
       basePrice:productDetail.basePrice,
       clientRating: productDetail.clientRating,
       fabric: productDetail.fabric?.name,
       gsm: productDetail.gsm,
       mrp: productDetail.mrp,
       ratingCount: productDetail.ratingCount,
       moq: productDetail.moq,
       availableSizes: productDetail.availableSizes?.join(', ') || '',
       productDescription: productDetail.productDescription.join('\n') || '',
       customizeOptions: productDetail.customizeOptions.join('\n') || '',
       moreInfo: productDetail.moreInfo.join('\n') || '',
     });
     setShowModal(true);
   };

   const handleClose = () => setShowModal(false);


   const handleInputChange = (e) => {
     const { name, value } = e.target;
     setFormData((prevState) => ({
       ...prevState,
       [name]: value,
     }));
   };
   const handleColorChange = (e) => {
  const { name, value } = e.target;
  setColorData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};


   const handleEditSubmit = (id) => {
  const requestBody = {
    name: formData.name,
    status: formData.status,
    colorOptions:formData.colorOptions,
    offerPrice: formData.offerPrice,
    basePrice:formData.basePrice,
    clientRating: formData.clientRating,
    fabric: { name: formData.fabric },
    gsm: formData.gsm,
    mrp: formData.mrp,
    ratingCount: formData.ratingCount,
    moq: formData.moq,
    availableSizes: formData.availableSizes.split(',').map((size) => size.trim()),
    productDescription: formData.productDescription.split('\n').map((desc) => desc.trim()),
    customizeOptions: formData.customizeOptions.split('\n').map((option) => option.trim()),
    moreInfo: formData.moreInfo.split('\n').map((info) => info.trim()),
  };

  PutToServer(fullPath.replace("/", ""), `product/${id}`, requestBody)
    .then((res) => {
      if (res.status === 200) {
        // Update the product details in the state directly
        setProductDetail((prevState) => ({
          ...prevState,
          name: formData.name,
          status: formData.status,
          colorOptions:formData.colorOptions,
          offerPrice: formData.offerPrice,
          basePrice:formData.basePrice,
          clientRating: formData.clientRating,
          fabric: { name: formData.fabric },
          gsm: formData.gsm,
          mrp: formData.mrp,
          ratingCount: formData.ratingCount,
          moq: formData.moq,
          availableSizes: formData.availableSizes.split(',').map((size) => size.trim()),
          productDescription: formData.productDescription.split('\n').map((desc) => desc.trim()),
          customizeOptions: formData.customizeOptions.split('\n').map((option) => option.trim()),
          moreInfo: formData.moreInfo.split('\n').map((info) => info.trim()),
        }));
        handleClose();  // Close the modal after successful update
      } else {
        console.error('Failed to update product:', res);
      }
    })
    .catch((error) => {
      console.error('Error while updating product:', error);
    });
};

const handleAdd = (id) => {
  event.preventDefault();

  const data = {
    name: colorData.name,
    status: colorData.status,
    color:colorData.color,
    colorName: colorData.colorName,
    colorIndex: colorData.colorIndex,
    shortName: colorData.shortName,

  };

  sendData(fullPath.replace("/", ""), `product/${id}/item`, data)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const buttonVariant = selectedProduct?.status === 'ACTIVE' ? 'success' : 'danger';

const buttonVariantProduct = productDetail?.status === 'ACTIVE' ? 'success' : 'danger';

const buttonVariantStock = selectedProduct?.stockStatus === 'IN_STOCK' ? 'success' : 'danger';



const toggleStatus = (id) => {
  setSelectedProduct((prevProduct) => {
    // Toggle the product status
    const newStatus = prevProduct.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';

    // Prepare the request body with the new status
    const requestBody = {
      status: newStatus,
    };

    // Make the API call to update the product status
    PutToServer(fullPath.replace("/", ""), `product/item/${id}/status`, requestBody)
      .then((res) => {
        if (res.status === 200) {
          // If the server responds successfully, update the product details in the state
          setSelectedProduct((prevProduct) => ({
            ...prevProduct,
            status: newStatus,  // Update the status directly in the state
          }));

          // If `productOptions` needs to be updated as well, ensure it is correctly handled
          setProductDetail((prevProductDetail) => ({
            ...prevProductDetail,
            // Make sure to update product options only if necessary,
            // for now I'm leaving this part assuming you want to update status as well.
            productOptions: prevProductDetail.productOptions?.map(option =>
              option.id === id ? { ...option, status: newStatus } : option
            ),
          }));
          handleCloseItem();
        } else {
          console.error('Failed to update product:', res);
        }
      })
      .catch((error) => {
        console.error('Error while updating product:', error);
      });

    // Return the updated product state immediately
    return {
      ...prevProduct,
      status: newStatus,
    };
  });
};

const toggleStock = (id) => {
  setSelectedProduct((prevProduct) => {
    // Toggle the product stock status
    const newStock = prevProduct.stockStatus === 'IN_STOCK' ? 'OUT_OF_STOCK' : 'IN_STOCK';

    // Prepare the request body with the new stock status
    const requestBody = {
      stockStatus: newStock,  // Update the stock status in the request body
    };

    // Make the API call to update the product stock status
    PutToServer(fullPath.replace("/", ""), `product/item/${id}/stock-status`, requestBody)
      .then((res) => {
        if (res.status === 200) {
          // If the server responds successfully, update the product details in the state
          setSelectedProduct((prevProduct) => ({
            ...prevProduct,
            stockStatus: newStock,  // Update the stock status directly in the state
          }));

          setProductDetail((prevProductDetail) => ({
            ...prevProductDetail,
            // Update the stock status in product options (if applicable)
            productOptions: prevProductDetail.productOptions?.map(option =>
              option.id === id ? { ...option, stockStatus: newStock } : option
            ),
          }));
          // Optionally close any modals or handle other UI changes
          handleCloseItem();
        } else {
          console.error('Failed to update product:', res);
        }
      })
      .catch((error) => {
        console.error('Error while updating product:', error);
      });

    // Return the updated product state immediately
    return {
      ...prevProduct,
      stockStatus: newStock,  // Update the stock status immediately in the state
    };
  });
};

const toggleProductStatus = (id) => {
  setProductDetail((prevProduct) => {
    // Toggle the product status
    const newStatus = prevProduct.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';

    // Prepare the request body with the new status
    const requestBody = {
      status: newStatus,
    };

    // Make the API call to update the product status
    PutToServer(fullPath.replace("/", ""), `product/${id}/status`, requestBody)
      .then((res) => {
        if (res.status === 200) {
          // If the server responds successfully, update the product details in the state
          setProductDetail((prevProduct) => ({
            ...prevProduct,
            status: newStatus,  // Update the status directly in the state
          }));
        } else {
          console.error('Failed to update product:', res);
        }
      })
      .catch((error) => {
        console.error('Error while updating product:', error);
      });

    // Return the updated product state immediately
    return {
      ...prevProduct,
      status: newStatus,
    };
  });
};



  const handleEditToggle = () => {

    if (!isEditing) {
      setEditedProduct({
        name: selectedProduct.name,
        mrp: selectedProduct.mrp,
        colorName: selectedProduct.colorName,
        color: selectedProduct.color,
        shortName: selectedProduct.shortName,
        orderIndex: selectedProduct.orderIndex
      });
    }
    setIsEditing(!isEditing);
  };

  const handleInputItem = (e) => {
    const { name, value } = e.target;
    setEditedProduct(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleUpdate = (id, itemId) => {
  const requestBody = {
    name: editedProduct.name,
    colorName: editedProduct.colorName,
    color: editedProduct.color, // Corrected from editedProducta
    shortName: editedProduct.shortName,
    orderIndex: editedProduct.orderIndex
  };

  PutToServer(fullPath.replace("/", ""), `product/${id}/item/${itemId}`, requestBody)
    .then((res) => {
      if (res.status === 200) {
        // Update the product details in the state directly
        setProductDetail((prevState) => ({
          ...prevState,
          productOptions: prevState.productOptions.map(option =>
            option.id === itemId
              ? {
                  ...option,
                  name: editedProduct.name,
                  colorName: editedProduct.colorName,
                  color: editedProduct.color, // Corrected from editedProducta
                  shortName: editedProduct.shortName,
                  orderIndex: editedProduct.orderIndex
                }
              : option
          )
        }));

        // Reset editing state and close modal
        setIsEditing(false);
        handleCloseItem();
      } else {
        console.error('Failed to update product:', res);
      }
    })
    .catch((error) => {
      console.error('Error while updating product:', error);
    });
};

const handleUpload = (e, imageType, productId) => {
  const files = Array.from(e.target.files); // Convert FileList to Array

  if (files.length === 0) {
    console.error("No files selected for upload.");
    return;
  }

  // Prepare FormData
  const formData = new FormData();
  files.forEach((file) => formData.append("files", file)); // Append each file to FormData

  // Log FormData to confirm it includes the images
  for (const [key, value] of formData.entries()) {
    console.log(`${key}:`, value);
  }

  // Determine API endpoint based on imageType and productId
  const apiEndpoint =
    imageType === "displayImages"
      ? `product/item/${productId}/images/display`
      : `product/item/${productId}/images`;

  // Send FormData to the server
  sendImage(fullPath.replace("/", ""), apiEndpoint, formData)
    .then((res) => {
      if (res.status === 200) {
         toast.success('Images uploaded successfully');
        console.log("Images uploaded successfully.");

      } else {
        console.error("Failed to upload images:", res);
      }
    })
    .catch((error) => {
      console.error("Error during image upload:", error);
    });

  e.target.value = ""; // Reset file input
};



const handleReplaceImage = (e, imageType,imageId, productId,) => {

const files = Array.from(e.target.files); // Convert FileList to Array
  // Prepare FormData
  const formData = new FormData();
  files.forEach((file) => formData.append("files", file)); // Append each file to FormData

  // Log FormData to confirm it includes the images
  for (const [key, value] of formData.entries()) {
    console.log(`${key}:`, value);
  }

  // Determine API endpoint based on imageType and productId
  const apiEndpoint =
    imageType === "displayImages"
      ? `product/item/${productId}/images/display?imageId=${imageId}`
      : `product/item/${productId}/images?imageId=${imageId}`;

  // Send FormData to the server
  sendImage(fullPath.replace("/", ""), apiEndpoint, formData)
    .then((res) => {
      if (res.status === 200) {
        console.log("Images uploaded successfully.");

      } else {
        console.error("Failed to upload images:", res);
      }
    })
    .catch((error) => {
      console.error("Error during image upload:", error);
    });

  e.target.value = ""; // Reset file input
};



  return (
    <>
    <Modal size="xl" show={showItemModal} onHide={handleCloseItem}>
        <Modal.Header>
          <Modal.Title>Item Details</Modal.Title>

          <Button variant={buttonVariant} onClick={() => toggleStatus(selectedProduct.id)}>
            {selectedProduct?.status || 'INACTIVE'}
          </Button>
          <Button  variant={buttonVariantStock} onClick={() => toggleStock(selectedProduct.id)}>
            {selectedProduct?.stockStatus || 'OUT OF STOCK'}
          </Button>
        </Modal.Header>
        <Modal.Body>
        <IconButton
          iconClassName="fs--2 me-1"
          variant="falcon-default"
          size="sm"
          icon="pencil-alt"
          className="position-absolute"
          style={{top:"15px", right:"0", backgroundColor:"transparent", border:"none", boxShadow:"none"}}
          onClick={handleEditToggle}
        >
          Edit
        </IconButton>
          {selectedProduct ? (
            <Row>

              {/* Carousel for Images */}
              <Col md={6}>
                {selectedProduct && (
                  <Row>
            <Col md={6}>
              {selectedProduct.displayImages?.length > 0 ? (
                <div className="d-flex flex-wrap gap-2">
                  {selectedProduct.displayImages.map((img) => (
                    <div
                      key={`display-${img.imageId}`}
                      className="p-1"
                      style={{ width: '30%', cursor: 'pointer', position: 'relative' }}
                      role="button"
                      aria-label="Select display image"
                      onClick={() =>
                        document.getElementById(`replaceDisplayImage-${img.imageId}`).click()
                      }
                    >
                      <Card>
                        <img
                          src={img.imageDesktop}
                          alt="Display Image"
                          className="img-fluid"
                          style={{ objectFit: 'cover', height: '100px', width: '100%' }}
                        />
                      </Card>
                      <input
                        type="file"
                        id={`replaceDisplayImage-${img.imageId}`}
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={(e) => handleReplaceImage(e, 'displayImages', img.imageId, selectedProduct.id)}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-muted">No display images uploaded yet.</p>
              )}
              <IconButton
                iconClassName="fs--2 me-1"
                variant="falcon-default"
                size="sm"
                icon="image"
                className="mt-2"
                style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                onClick={() => document.getElementById('uploadDisplayImages').click()}
              >
                Upload Display Images
              </IconButton>
              <input
                type="file"
                id="uploadDisplayImages"
                accept="image/*"
                multiple
                style={{ display: 'none' }}
                onChange={(e) => handleUpload(e, 'displayImages', selectedProduct.id)}
              />
            </Col>

            <Col md={6}>
              {selectedProduct.images?.length > 0 ? (
                <div className="d-flex flex-wrap gap-2">
                  {selectedProduct.images.map((img) => (
                    <div
                      key={`regular-${img.imageId}`}
                      className="p-1"
                      style={{ width: '30%', cursor: 'pointer', position: 'relative' }}
                      role="button"
                      aria-label="Select regular image"
                      onClick={() =>
                        document.getElementById(`replaceRegularImage-${img.imageId}`).click()
                      }
                    >
                      <Card>
                        <img
                          src={img.imageDesktop}
                          alt="Regular Image"
                          className="img-fluid"
                          style={{ objectFit: 'cover', height: '100px', width: '100%' }}
                        />

                      </Card>
                      <input
                        type="file"
                        id={`replaceRegularImage-${img.imageId}`}
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={(e) => handleReplaceImage(e, 'images', img.imageId,selectedProduct.id)}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-muted">No regular images uploaded yet.</p>
              )}
              <IconButton
                iconClassName="fs--2 me-1"
                variant="falcon-default"
                size="sm"
                icon="image"
                className="mt-2"
                style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                onClick={() => document.getElementById('uploadRegularImages').click()}
              >
                Upload Regular Images
              </IconButton>
              <input
                type="file"
                id="uploadRegularImages"
                accept="image/*"
                multiple
                style={{ display: 'none' }}
                onChange={(e) => handleUpload(e, 'images', selectedProduct.id)}
              />
            </Col>
          </Row>


       )}
     </Col>
              {/* Product Details */}
              <Col md={4}>
                {isEditing ? (
                  <>
                    <div className="mb-3">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={editedProduct.name || ''}
                        onChange={handleInputItem}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Price</label>
                      <input
                        type="text"
                        className="form-control"
                        name="mrp"
                        value={editedProduct.mrp || ''}
                        onChange={handleInputItem}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Color Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="colorName"
                        value={editedProduct.colorName || ''}
                        onChange={handleInputItem}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Color Code</label>
                      <input
                        type="text"
                        className="form-control"
                        name="color"
                        value={editedProduct.color || ''}
                        onChange={handleInputItem}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Short Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="color"
                        value={editedProduct.shortName || ''}
                        onChange={handleInputItem}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Order Index</label>
                      <input
                        type="text"
                        className="form-control"
                        name="color"
                        value={editedProduct.orderIndex || ''}
                        onChange={handleInputItem}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <h5>{selectedProduct.name}</h5>
                    <p>Price: {selectedProduct.mrp || 'N/A'}</p>
                    <p>Color: {selectedProduct.colorName || 'N/A'}</p>
                    <p>Color Code: {selectedProduct.color || 'N/A'}</p>
                    <p>Short Name: {selectedProduct.shortName || 'N/A'}</p>
                    <p>OrderIndex: {selectedProduct.orderIndex || 'N/A'}</p>
                  </>
                )}
              </Col>
            </Row>
          ) : (
            'No details available'
          )}
        </Modal.Body>
        <Modal.Footer>
          {isEditing ? (
            <Button
              variant="primary"
              onClick={() => handleUpdate(productDetail.id, selectedProduct.id)}
            >
              Save Changes
            </Button>
          ) : (
            <Button variant="secondary" onClick={handleCloseItem}>
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    <Modal size="lg" show={showColorModal} onHide={handleCloseColor}>
  <Modal.Header closeButton>
    <Modal.Title>Add Color</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Row>
        <Col md={6}>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={colorData.name}
            onChange={handleColorChange}
          />
        </Form.Group>

        {/* Status */}
        <Form.Group className="mb-3">
          <Form.Label>Color Name</Form.Label>
          <Form.Control
            type="text"
            name="colorName"
            value={colorData.colorName}
              onChange={handleColorChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Color Code</Form.Label>
          <Form.Control
            type="text"
            name="color"
            value={colorData.color}
              onChange={handleColorChange}
          />
        </Form.Group>


        </Col>

        <Col md={6}>

                {/* Offer Price */}
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    type="text"
                    name="status"
                    value={colorData.status}
                      onChange={handleColorChange}
                  />
                </Form.Group>

                {/* Client Rating */}
                <Form.Group className="mb-3">
                  <Form.Label>Short Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="shortName"
                    value={colorData.shortName}
                      onChange={handleColorChange}
                  />
                </Form.Group>

                {/* Fabric */}
                <Form.Group className="mb-3">
                  <Form.Label>Order Index</Form.Label>
                  <Form.Control
                    type="number"
                    name="orderIndex"
                    value={colorData.orderIndex}
                      onChange={handleColorChange}
                  />
                </Form.Group>

        </Col>
      </Row>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="primary" onClick={() => handleAdd(productDetail.id)}>
      Add
    </Button>
  </Modal.Footer>
</Modal>

    <Modal size="xl" show={showModal} onHide={handleClose}>
  <Modal.Header closeButton>
    <Modal.Title>Edit Product Details</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Row>
        <Col md={5}>
          {/* Product Name */}
          <Form.Group className="mb-3">
            <Form.Label>Product Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Status */}
          <Form.Group className="mb-3">
            <Form.Label>Status</Form.Label>
            <Form.Control
              type="text"
              name="status"
              value={formData.status}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Available Color</Form.Label>
            <Form.Control
              type="text"
              name="colorOptions"
              value={formData.colorOptions}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Offer Price */}
          <Form.Group className="mb-3">
            <Form.Label>Offer Price</Form.Label>
            <Form.Control
              type="number"
              name="offerPrice"
              value={formData.offerPrice}
              onChange={handleInputChange}
            />
          </Form.Group>
            {/* Base Price */}
          <Form.Group className="mb-3">
            <Form.Label>Base Price</Form.Label>
            <Form.Control
              type="number"
              name="basePrice"
              value={formData.basePrice}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Client Rating */}
          <Form.Group className="mb-3">
            <Form.Label>Client Rating</Form.Label>
            <Form.Control
              type="number"
              name="clientRating"
              value={formData.clientRating}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Fabric */}
          <Form.Group className="mb-3">
            <Form.Label>Fabric</Form.Label>
            <Form.Control
              type="text"
              name="fabric"
              value={formData.fabric}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* GSM */}
          <Form.Group className="mb-3">
            <Form.Label>GSM</Form.Label>
            <Form.Control
              type="text"
              name="gsm"
              value={formData.gsm}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* MRP */}
          <Form.Group className="mb-3">
            <Form.Label>MRP</Form.Label>
            <Form.Control
              type="number"
              name="mrp"
              value={formData.mrp}
              onChange={handleInputChange}
            />
          </Form.Group>


          {/* Rating Count */}
          <Form.Group className="mb-3">
            <Form.Label>Rating Count</Form.Label>
            <Form.Control
              type="number"
              name="ratingCount"
              value={formData.ratingCount}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* MOQ */}
          <Form.Group className="mb-3">
            <Form.Label>MOQ</Form.Label>
            <Form.Control
              type="number"
              name="moq"
              value={formData.moq}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Available Sizes */}
          <Form.Group className="mb-3">
            <Form.Label>Available Sizes</Form.Label>
            <Form.Control
              type="text"
              name="availableSizes"
              value={formData.availableSizes}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>

        <Col md={7}>
          {/* Product Description */}
          <Form.Group className="mb-3">
            <Form.Label>Product Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              name="productDescription"
              value={formData.productDescription}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Customize Options */}
          <Form.Group className="mb-3">
            <Form.Label>Customize Options</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              name="customizeOptions"
              value={formData.customizeOptions}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* More Info */}
          <Form.Group className="mb-3">
            <Form.Label>More Info</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              name="moreInfo"
              value={formData.moreInfo}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleClose}>
      Close
    </Button>
    <Button variant="primary" onClick={() => handleEditSubmit(productDetail.id)}>
      Update
    </Button>
  </Modal.Footer>
</Modal>

      <Card className="mb-3">
        <Card.Body>
          <Row>
            {/* Product Images */}
            <Col lg={3} className="mb-4 mb-lg-0">
              {carouselImages.length > 0 ? (
                <Carousel>
                  {carouselImages.map((image, index) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100"
                        src={image.imageDesktop}
                        alt={`Product Image ${index + 1}`}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : (
                <p>No images available for this product.</p>
              )}
            </Col>

            {/* Product Details */}
            <Col lg={7}>
              <h4>{productDetail.name}</h4>
              <h5 className="text-success">₹{productDetail.offerPrice}</h5>
                <p className="text-muted text-decoration-line-through">₹{productDetail.mrp}</p>
                <Badge bg="warning" text="dark">
                  <FontAwesomeIcon icon={faStar} /> {productDetail.clientRating} ({productDetail.ratingCount} ratings)
                </Badge>
              {/* Fabric and GSM */}
              <div className="mt-3">
                    <strong>Fabric:</strong> {productDetail.fabric.name}
                    <br />
                    <strong>GSM:</strong> {productDetail.gsm}
                    <br />
                    <strong>colorOptions:</strong> {numberOfColors}
                    <br />
                  <strong>MOQ:</strong> {productDetail.moq}
                      <br />
                  <strong>Base Price</strong> {productDetail.basePrice}
              </div>
              <div>
              <h3>Available Sizes</h3>
              <div className="btn-group" role="group" aria-label="Available Sizes">
                {productDetail.availableSizes.map((size, index) => (
                  <button
                    key={index}
                    type="button"
                    className="btn btn-outline-primary"
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>

              <IconButton
                iconClassName="fs--2 me-1"
                variant="falcon-default"
                size="sm"
                icon="pencil-alt"
                onClick={() => handleShow()}
                className="position-absolute"
                style={{top:"0",right:"0",backgroundColor:"transparent",border:"none",boxShadow:"none"}}
              >
             Edit
              </IconButton>
            </Col>
            <Col lg={2}>
            <Button variant={buttonVariantProduct} onClick={() => toggleProductStatus(productDetail.id)}>
                {productDetail.status || 'INACTIVE'}
             </Button>
            </Col>
          </Row>
          {/* Available Colors */}
          <div className="product-options mt-2">
            <div className="row">
            {productDetail?.productOptions?.map((option, index) => (
              <div
                key={option.id}
                className="col-1 mb-1"
                onClick={() => handleShowItem(option)}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => e.key === 'Enter' && handleColorClick(option)}
              >
                <div className="option-images" style={{ position: 'relative' }}>
                  {Array.isArray(option.displayImages) && option.displayImages.length > 0 ? (
                    <img
                      src={option.displayImages[0].imageDesktop}
                      alt={option.colorName || 'Color option'}
                      className="img-fluid"
                      style={{
                        cursor: 'pointer',
                        opacity: option.stockStatus === 'OUT_OF_STOCK' ? 0.3 : 1, // Apply low opacity for out of stock
                      }}
                    />
                  ) : (
                    Array.isArray(option.images) &&
                    option.images.length > 0 && (
                      <img
                        src={option.images[0].imageDesktop}
                        alt={option.colorName || 'Color option'}
                        className="img-fluid"
                        style={{
                          cursor: 'pointer',
                          opacity: option.stockStatus === 'OUT_OF_STOCK' ? 0.3 : 1, // Apply low opacity for out of stock
                        }}
                      />
                    )
                  )}

                  {/* Display "Out of Stock" text as a line over the image */}
                  {option.stockStatus === 'OUT_OF_STOCK' && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '16px',
                        left: '0',
                        right: '0',
                        color: 'rgba(255, 0, 0, 0.6)',
                        textAlign: 'center',
                        padding: '5px 0',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        zIndex: 10,
                      }}
                    >
                      Out of Stock
                    </div>
                  )}
                </div>
                <p className="text-center mt-2" style={{ fontSize: "12px" }}>
                  {option.colorName || 'Default Color'}
                  <span className="ml-2">
                    {option.status === 'ACTIVE' ? (
                      <FaCheckCircle style={{ color: 'green' }} />
                    ) : option.status === 'INACTIVE' ? (
                      <FaTimesCircle style={{ color: 'red' }} />
                    ) : (
                      <span></span>
                    )}
                  </span>
                </p>
              </div>
            ))}
                <IconButton
                  variant="falcon-primary"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  onClick={() => handleShowColor()}
                  style={{top:"0",right:"0",backgroundColor:"transparent",border:"none",boxShadow:"none"}}
                >
                  Add Color
                </IconButton>
            </div>
          </div>
          <div className="overflow-hidden mt-4">
            <Tab.Container defaultActiveKey="description">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link
                    eventKey="description"
                    className="ps-0 cursor-pointer outline-none"
                  >
                    Description
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="specifications"
                    className="px-2 px-md-3 cursor-pointer outline-none"
                  >
                    Specifications
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="reviews"
                    className="px-2 px-md-3 cursor-pointer outline-none"
                  >
                    Reviews
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="description">
                <div>
                <h3>Product Description</h3>
                <ul className="list-group">
                  {productDetail.productDescription.map((item, index) => (
                    <li key={index} >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div>
              <h3>Product CustomizeOptions</h3>
              <ul className="list-group">
                {productDetail.customizeOptions.map((item, index) => (
                  <li key={index} >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div>
            <h3>More Info</h3>
            <ul className="list-group">
              {productDetail.moreInfo.map((item, index) => (
                <li key={index} >
                  {item}
                </li>
              ))}
            </ul>
          </div>

                </Tab.Pane>
                <Tab.Pane eventKey="specifications">
                  <Table className="fs--1 mt-3">

                  </Table>
                </Tab.Pane>
                <Tab.Pane eventKey="reviews">
                  <Row className="mt-3">
                    <Col lg={6} className="mb-4 mb-lg-0">

                    </Col>
                    <Col lg={6} className="ps-lg-5">
                      <Form>
                        <h5 className="mb-3">Write your Review</h5>
                        <div className="mb-3">
                          <Form.Label className="mb-0">Rating:</Form.Label>
                          <StarRating className="d-block fs-3" rating={0} />
                        </div>
                        <div className="mb-3">
                          <Form.Label>Name:</Form.Label>
                          <Form.Control type="text" />
                        </div>
                        <div className="mb-3">
                          <Form.Label>Email:</Form.Label>
                          <Form.Control type="text" />
                        </div>
                        <div className="mb-3">
                          <Form.Label>Review:</Form.Label>
                          <Form.Control as="textarea" rows="3" type="text" />
                        </div>
                        <Button variant="primary" type="submit">
                          Submit
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProductName;
