import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import is from 'is_js';
import MainLayout from './MainLayout';
import SettingsToggle from 'components/settings-panel/SettingsToggle';
import SettingsPanel from 'components/settings-panel/SettingsPanel';

import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Followers from 'components/app/social/followers/Followers';
import Notifications from 'components/app/social/notifications/Notifications';
import ActivityLog from 'components/app/social/activity-log/ActivityLog';

import Invoice from 'components/app/e-commerce/Invoice';
import LeadDetails from 'components/app/leadinfo/leaddetails';
import Checkout from 'components/app/e-commerce/checkout/Checkout';
import ShoppingCart from 'components/app/e-commerce/cart/ShoppingCart';
import CustomersDetails from 'components/app/e-commerce/customers-details/CustomersDetails';
import OrderDetails from 'components/app/orders/order-details/OrderDetails';
import OrderProcess from 'components/app/orders/order-process/OrderProcess';
import Products from 'components/app/e-commerce/product/Products';
import ProductDetails from 'components/app/e-commerce/product/product-details/ProductDetails';
import Orders from 'components/app/orders/order-list/Orders';

import PendingList from 'components/app/pending-orders/pending-list';
import SiteNumber from 'components/app/sitenumbers/sitenumber-list';

import Customers from 'components/app/e-commerce/customers/Customers';
import LeadHistory from 'components/app/leadinfo/leadhistory';

import CreateEvent from 'components/app/events/create-an-event/CreateEvent';
import EventList from 'components/app/events/event-list/EventList';
import EmailDetail from 'components/app/email/email-detail/EmailDetail';
import Compose from 'components/app/email/compose/Compose';
import Inbox from 'components/app/email/inbox/Inbox';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import CardLogin from 'components/authentication/card/Login';
import Logout from 'components/authentication/card/Logout';
import CardRegistration from 'components/authentication/card/Registration';
import CardForgetPassword from 'components/authentication/card/ForgetPassword';
import CardConfirmMail from 'components/authentication/card/ConfirmMail';
import CardPasswordReset from 'components/authentication/card/PasswordReset';
import CardLockScreen from 'components/authentication/card/LockScreen';

import Dashboard from 'components/dashboards/default';
import AppContext from 'context/Context';
import { AuthStateContext, AuthDispatchContext, logoutSuccess, setUserLogin, setAccessToken } from "context/auth"
import { sendData, parseJwt } from 'helpers/utils';

const Layout = () => {
  const state = useContext(AuthStateContext);
  const dispatch = useContext(AuthDispatchContext);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  //checking Up Login In Refresh
  const checkingUpLogin =()=>{
    var access = localStorage.getItem("access");
    var refresh = localStorage.getItem("refresh");
    var curTime = new Date();
    curTime = Math.floor(curTime.getTime()/1000.0);
    var refreshExp = false;
    // checking If Refresh Token Is Present
    if(refresh !== null){
        // checking refresh Token Has Expired
        if(curTime > parseJwt(refresh).exp){
            refreshExp = true;
        }
    }
    if(access !== null && refresh !== null && !refreshExp){
      let accessExp = parseJwt(access).exp;
      let hasError = false;
      if(curTime > accessExp){
          sendData(window.location.pathname.replace("/",""),"login/refresh",{access,refresh})
          .then((res)=>{
            console.log("test",res)
            setAccessToken(dispatch, res.data.access)
            access = res.data.access
          })
          .catch((err)=>
           console.log("test",err)
        )}
      if(!hasError){
        // setting Up Login when  Refreshing The Page
        setUserLogin(dispatch,access,refresh)
      }
      else{
        // Token Time OF access Token And Refresh Token Has Expired
        logoutSuccess(dispatch)
      }

    }
    else{
      // logout When No Token Is Available
      logoutSuccess(dispatch)
    }

  }

 useEffect(checkingUpLogin,[])

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);



  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
        {/*- ------------- Authentication ---------------------------  */}

        {/*- ------------- Card ---------------------------  */}
        <Route path="/signin" element={<CardLogin />} />
        <Route
          path="/register"
          element={<CardRegistration />}
        />
        <Route path="/logout" element={<Logout />} />
        <Route
          path="/forgot-password"
          element={<CardForgetPassword />}
        />
        <Route
          path="/reset-password"
          element={<CardPasswordReset />}
        />
        <Route
          path="/confirm-mail"
          element={<CardConfirmMail />}
        />
        <Route
          path="/lock-screen"
          element={<CardLockScreen />}
        />

        {/* //--- MainLayout Starts  */}

        <Route element={<MainLayout />}>
          {/*Dashboard*/}
          <Route path="/" element={<Dashboard />} />

          {/* E Commerce */}
          <Route
            path="orders/order-details"
            element={<OrderDetails />}
          />
          <Route
            path="orders/order-process"
            element={<OrderProcess />}
          />

          <Route
            path="/pending/pending-list"
            element={<PendingList />}
             />
             <Route
               path="/sitenumber/sitenumber-list"
               element={<SiteNumber />}
                />
          <Route
            path="leadinfo/leaddetails"
            element={<LeadDetails />}
          />
          <Route path="orders/order-list" element={<Orders />} />
          <Route path="e-commerce/checkout" element={<Checkout />} />
          <Route path="e-commerce/shopping-cart" element={<ShoppingCart />} />
          <Route path="e-commerce/customers" element={<Customers />} />
          <Route
            path="e-commerce/customer-details"
            element={<CustomersDetails />}
          />

          <Route
            path="e-commerce/product/product-details"
            element={<ProductDetails />}
          />
          <Route
            path="e-commerce/product/product-details/:productId"
            element={<ProductDetails />}
          />

          <Route
            path="e-commerce/product/:productLayout"
            element={<Products />}
          />

          <Route path="e-commerce/invoice" element={<Invoice />} />
          <Route path="leadinfo/leaddetails" element={<LeadDetails />} />

          {/*App*/}
          <Route path="social/activity-log" element={<ActivityLog />} />
          <Route path="social/notifications" element={<Notifications />} />
          <Route path="social/followers" element={<Followers />} />
          <Route path="events/create-an-event" element={<CreateEvent />} />
          <Route path="events/event-list" element={<EventList />} />

          {/* Email */}
          <Route path="email/email-detail" element={<EmailDetail />} />
          <Route path="email/inbox" element={<Inbox />} />
          <Route path="email/compose" element={<Compose />} />
        </Route>

        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <SettingsToggle />
      <SettingsPanel />
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
