import react,{useEffect,useState,useRef} from "react";
import {ResponseFromServer,sendData,PutToServer} from 'helpers/utils';
import { Card,Row,Col,DropdownButton,Dropdown} from 'react-bootstrap';
import CalculateImageDimension from 'helpers/canvasReRender';
import CreateDesigns from './createDesign';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
const PendingDesign = ()=>{
  const [activePage,setActivePage] = useState(0)
  const [orderStatus,setOrderStatus] = useState('DESIGNING')
  const [ordeData,setOrderData] = useState([])
  const parentNode =  useRef(null);
  const [pagesCount,setPageCount] = useState(null)
  const [loading,setLoading] = useState(false)

  const handleSelect = async(e)=>{
    setLoading(true)
    setOrderStatus(e)
    await ResponseFromServer('pending/pending-design',`design/all?status=${e}&page=${activePage}&size=50`)
    .then((res)=>{
        setOrderData(res.data)
        setPageCount(res.data.totalPages)
        setLoading(false)
    })
    .catch((err)=>{
        console.log("test",err)
    })
  }

  const fetchData = async ()=>{
      await ResponseFromServer('pending/pending-design',`design/all?status=${orderStatus}&page=0&size=50`)
      .then((res)=>{
          setOrderData(res.data)
          setPageCount(res.data.totalPages)
      })
      .catch((err)=>{
          console.log("test",err)
      })
  }





  const gotoPage = async (index)=>{
    setLoading(true)
    setActivePage(index)
    await ResponseFromServer('pending/pending-design',`design/all?status=${orderStatus}&page=${index}&size=50`)
    .then((res)=>{
        setOrderData(res.data)
        setPageCount(res.data.totalPages)
        setLoading(false)
        setActivePage(index)
    })

  }

  const renderImage = (imageData,idx,canvas)=>{
      return(
        <Col>
          <CalculateImageDimension   imageUrl={imageData.imageUrl} node={parentNode} canx={canvas} />
          <img
            src={imageData.imageUrl}
            alt="Logitech G305 Gaming Mouse"
            className="h-100 w-100 fit-cover rounded"
            ref={parentNode}
          />
        </Col>

      )

  }


  useEffect(fetchData,[])

  return(<>
    <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Previous Orders</h5>
            </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <DropdownButton
              id="dropdown-basic-button"
              title={orderStatus}
              onSelect={handleSelect}
              className="text-uppercase"
            >
              <Dropdown.Item eventKey="DESIGNING">Desingning</Dropdown.Item>
              <Dropdown.Item eventKey="PENDING">Pending</Dropdown.Item>
              <Dropdown.Item eventKey="CONFIRMED">Confirmed</Dropdown.Item>
              <Dropdown.Item eventKey="CANCELLED">Cancelled</Dropdown.Item>


            </DropdownButton>
            <Row  className="mt-4 shadow-sm p-3 mb-3 bg-body rounded">
            {!loading?(ordeData?.content?.map((item,idx)=>(
                <CreateDesigns item={item}  key={idx}/>
              ))):(null)}
              </Row>
          </Card.Body>

      </Card>
      <AdvanceTablePagination table pageCount={pagesCount} gotoPage={gotoPage}  pageIndex={activePage}/>

    </>)
}

export default PendingDesign;
