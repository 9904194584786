import React, { useState, useEffect } from 'react';
import { Col, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';

const OrderCard = ({ item, handleShow, handleShowModal, addNotes, shipModalSetter }) => {


  return (
      <div>
        <h6>
          Order Id: {item?.orderId ?? "N/A"} (
          <a href={`/order/download-zip?orderId=${item?.orderId ?? ""}&designUUID=${item?.design?.designUUID ?? ""}`}>Download assets</a>
          )
        </h6>
        <h6>
          <a href={`${process.env.REACT_APP_MAIN}/checkout?itemId=${item?.orderId ?? ""}&designId=${item?.design?.designUUID ?? ""}`} target="_blank" rel="noopener noreferrer">
            {item?.productName ?? "N/A"}
          </a>
          (<span className="fw-bold">{item?.color ?? "N/A"}</span>)
        </h6>
        <div className="d-lg-block">
          <div className="d-flex">
            <h6 className="text-dark me-2">
              Quantity: <span className="text-primary">{item?.totalQuantity ?? 0}</span>
            </h6>
            <h6 className="d-flex align-self-center">
              ({item?.productQuantity?.map((sizeName, idx) => (
                sizeName?.count === 0 ? null : (
                  <span className="ms-1" key={idx}>
                    <span className="text-dark text-uppercase fw-bold">
                      {sizeName?.size ?? "N/A"} = <span className="text-uppercase text-primary me-1">{sizeName?.count ?? 0}</span>
                    </span>
                  </span>
                )
              )) ?? "N/A"})
            </h6>
          </div>
          <h6 className="text-dark me-2">Order Date: <span className="text-primary">{item?.orderDate ?? "N/A"}</span></h6>
          <h6 className="text-dark me-2">
            Delivery Date: <span className="text-primary">{item?.priceResponse?.deliveryDate ?? "N/A"}</span>
            ({item?.priceResponse?.deliveryType === "EXPRESS" ? (
              <span style={{ color: "#F75900" }}>{item?.priceResponse?.deliveryType}</span>
            ) : (
              <span>{item?.priceResponse?.deliveryType ?? "N/A"}</span>
            )})
          </h6>
          <h6 className="text-dark me-2">
            Unit Cost: <span className="text-primary">{item?.priceResponse?.unitCost ?? "N/A"}</span>,
            Total Cost: <span className="text-primary">{item?.priceResponse?.totalCost ?? "N/A"}</span>
          </h6>
          <h6 className="text-dark me-2">
            Paid: <span className="text-primary">{item?.paidAmount ?? "N/A"}</span>,
            Due: {item?.dueAmount > 0 ? (
              <span style={{ color: "#F76558" }}>{item?.dueAmount ?? "N/A"}</span>
            ) : (
              <span>{item?.dueAmount ?? "N/A"}</span>
            )}
            (<span style={{ color: "blue", cursor: "pointer" }} onClick={() => handleShow(item?.orderId)}>Payment Details</span>)
          </h6>
        </div>

        <div className="text-dark fw-bolder mt-2">Buyer Details</div>
        <div className="d-flex flex-column border p-2 rounded-4 position-relative">
          <IconButton
            icon="pencil-alt"
            size="sm"
            onClick={() => handleShowModal(item)}
            className="position-absolute"
            style={{ top: "0", right: "0", backgroundColor: "transparent", border: "none" }}
          >
            Edit
          </IconButton>
          <h6><strong>Name:</strong> {item?.shippingAddress?.name ?? "N/A"}</h6>
          <h6><strong>Address:</strong> {`${item?.shippingAddress?.house ?? ""}, ${item?.shippingAddress?.street ?? ""}, ${item?.shippingAddress?.city ?? ""}, ${item?.shippingAddress?.pinCode ?? ""}, ${item?.shippingAddress?.state ?? ""}`.trim() || "N/A"}</h6>
          <h6><strong>Contact:</strong> {item?.shippingAddress?.phone ?? "N/A"}, {item?.shippingAddress?.altPhone ?? "N/A"}</h6>
          <h6><strong>Email:</strong> {item?.shippingAddress?.email ?? "N/A"}</h6>
          
        </div>
      </div>

  );
};

export default OrderCard;
