import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { sendData } from 'helpers/utils';
import { useDropzone } from 'react-dropzone';
import IconButton from 'components/common/IconButton';
import { FaTrash } from 'react-icons/fa'; 

const CreateLead = (props) => {
  const [files, setFiles] = useState([]);
  const [isFullAddressChecked, setIsFullAddressChecked] = useState(false);
  const [products, setProducts] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [leadRequirements, setLeadRequirements] = useState([
   {
     productName: '',
     quantity: '',
     sizes: {},
     customProductName: '',
     customProductDetails: '',
   },
 ]);


  const [lead, setLead] = useState({
      name: '',
      email: '',
      phoneNumber: '',
      altPhone: '',
      customerType: '',
      customerTypeName: '',
      address: '',
      companyName: '',
      gender: '',
      society: '',
      pinCode: '',
      state: '',
      city: '',
      gstDetails: '',
      notes: '',
      status: '',
      possibility: '',
      followUpDate: null, // Initialized as null
      preferredTime: '',
      language: '',
      leadSource: '',
      leadChannel: '',
      leadRequirements: [],
    });

    const handleChange = (event) => {
      const { name, value } = event.target;
      setLead((prevLead) => ({
        ...prevLead,
        [name]: value, // Set to null if the input is cleared
      }));
    };

    const handleAdd = (index, event) => {
    const { name, value } = event.target;
    const updatedRequirements = [...leadRequirements];

    // Ensure sizes object is initialized
    if (!updatedRequirements[index].sizes) {
      updatedRequirements[index].sizes = {};
    }

    // Handle category type change
    if (name === 'categoryType') {
      updatedRequirements[index] = {
        ...updatedRequirements[index],
        categoryType: value,
        productName: value === 'Others' ? updatedRequirements[index].customProductName : value, // Set productName if "Others"
      };
    } else if (['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'].includes(name)) {
      // Parse the size value as an integer; default to 0 if empty
      updatedRequirements[index].sizes[name] = value ? parseInt(value, 10) : 0;
    } else {
      updatedRequirements[index][name] = value;

      // If "Others" is selected, set productName to customProductName
      if (updatedRequirements[index].categoryType === 'Others') {
        updatedRequirements[index].productName = updatedRequirements[index].customProductName;
      }
    }

    // Update the state with the modified requirements
    setLeadRequirements(updatedRequirements);
  };

     const addLeadRequirement = () => {
        setLeadRequirements((prevRequirements) => [
          ...prevRequirements,
          {
            productName: '',
            quantity: '',
            sizes: {},
            customProductName: '',
            customProductDetails: '',
            sizeQuantityList: [],  // Initialize sizeQuantityList as an empty array
          },
        ]);
      };

      const deleteLeadRequirement = (index) => {
         const updatedRequirements = leadRequirements.filter((_, i) => i !== index);
         setLeadRequirements(updatedRequirements);
       };


     const handleAddSizeQuantity = (index, size, count) => {
       setLeadRequirements((prevRequirements) => {
         const updatedRequirements = [...prevRequirements];
         const updatedRequirement = { ...updatedRequirements[index] };

         const existingSizeIndex = updatedRequirement.sizeQuantityList.findIndex((s) => s.size === size);

         if (existingSizeIndex !== -1) {

           updatedRequirement.sizeQuantityList[existingSizeIndex].count = count;
         } else {
           updatedRequirement.sizeQuantityList.push({ size, count });
         }

         updatedRequirements[index] = updatedRequirement;
         return updatedRequirements;
       });
     };


  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      name: lead.name,
      email: lead.email,
      phoneNumber: lead.phoneNumber,
      altPhone: lead.altPhone,
      customerType: lead.customerType,
      customerTypeName: lead.customerTypeName,
      address: lead.address,
      city: lead.city,
      state: lead.state,
      pinCode: lead.pinCode,
      gstDetails: lead.gstDetails,
      notes: lead.notes,
      status: lead.status,
      possibility: lead.possibility,
      followUpDate: lead.followUpDate || null,
      preferredTime: lead.preferredTime,
      language: lead.language,
      leadSource: lead.leadSource,
      leadChannel: lead.leadChannel,
      leadRequirements: leadRequirements.map((requirement) => ({
      productName: requirement.productName,
      quantity: requirement.quantity,
      quotation: requirement.quotation || null,
      designUrl: requirement.designUrl || null,
      sizeQuantityList: Object.entries(requirement.sizes || {}).map(([size, count]) => ({
        size,        // The size (e.g., 'M')
        count: count || 0, // The count, defaulting to 0 if undefined
      })),
    })),
    };

    sendData('leadinfo/leaddetails', 'lead', data)
      .then((res) => {
        console.log(res);
        props.setSmShow(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };



  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      );
    }
  });

  const handleRemove = path => {
    setFiles(files.filter(file => file.path !== path));
  };

  const handleCheckboxChange = () => {
    setIsFullAddressChecked(!isFullAddressChecked);
  };

  return (
    <>
      <Modal
        size="xl"
        aria-labelledby="example-modal-sizes-title-sm"
        show={props.smShow}
        onHide={() => props.setSmShow(false)}
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            Create Lead
          </Modal.Title>
          <FalconCloseButton size='sm' onClick={() => props.setSmShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3 g-3">
              <Form.Label className="text-dark">Customer Details</Form.Label>
            </Row>
            <Row className="mb-3 g-3 border border-200 rounded">
              <Form.Group as={Col} controlId="formGridName" className="my-1">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Name" name='name' value={lead.name} onChange={handleChange} />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridEmail" className="my-1">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Email" name='email' value={lead.email} onChange={handleChange} />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridPhone" className="my-1">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="text" placeholder="Phone" name='phoneNumber' value={lead.phoneNumber} onChange={handleChange} />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridAltPhone" className="my-1">
                <Form.Label>Alt Phone</Form.Label>
                <Form.Control type="text" placeholder="Alt Phone" name='altPhone' value={lead.altPhone} onChange={handleChange} />
              </Form.Group>
            </Row>
            <Row className="mb-3 g-3">
              <Form.Label>Customer Location</Form.Label>
            </Row>
            <Row className="mb-3 g-3 border border-200 rounded">
              <Form.Group as={Col} controlId="formGridCity" className="my-1">
                <Form.Label>City</Form.Label>
                <Form.Control type="text" placeholder="City" name='city' value={lead.city} onChange={handleChange} />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridState" className="my-1">
                <Form.Label>State</Form.Label>
                <Form.Control type="text" placeholder="State" name='state' value={lead.state} onChange={handleChange} />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridPinCode" className="my-1">
                <Form.Label>Pin Code</Form.Label>
                <Form.Control type="number" placeholder="Pin Code" name='pinCode' value={lead.pinCode} onChange={handleChange} />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridGst" className="my-1">
                <Form.Label>GST Number</Form.Label>
                <Form.Control type="text" placeholder="GST Details" name='gstDetails' value={lead.gstDetails} onChange={handleChange} />
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Check If Full Address Available"
                  checked={isFullAddressChecked}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>
              {isFullAddressChecked && (
                <Form.Group as={Col} lg={8} controlId="formFullAddress" className="my-1">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Road Name/ Area/ Colony/ Street*"
                    name="address"
                    value={lead.address}
                    onChange={handleChange}
                  />
                </Form.Group>
              )}
            </Row>
            <Row className="mb-3 g-3">
              <Form.Label>Customer Type</Form.Label>
            </Row>
            <Row className="mb-3 g-3 border border-200 rounded">
              <Form.Group as={Col} lg="4" controlId="formGridCustomerType" className="my-1">
                <Form.Label>Customer Type</Form.Label>
                <Form.Select
                  className="mb-2"
                  name='customerType'
                  value={lead.customerType}
                  onChange={handleChange}
                >
                  <option value='' disabled hidden>Select Customer Type</option>
                  <option value='INDIVIDUAL'>Individual</option>
                  <option value='BUSINESS'>Business</option>
                  <option value='COLLEGE'>College</option>
                  <option value='SOCIETY'>Society</option>
                </Form.Select>
              </Form.Group>

              {/* Conditional rendering based on selected customerType */}
              {lead.customerType === 'BUSINESS' && (
                <Form.Group as={Col} lg="4" controlId="formGridBusinessName" className="my-1">
                  <Form.Label>Business Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Business Name"
                    name='customerTypeName'
                    value={lead.customerTypeName}
                    onChange={handleChange}
                  />
                </Form.Group>
              )}

              {lead.customerType === 'COLLEGE' && (
                <Form.Group as={Col} lg="4" controlId="formGridCollegeName" className="my-1">
                  <Form.Label>College Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter College Name"
                    name='customerTypeName'
                    value={lead.customerTypeName}
                    onChange={handleChange}
                  />
                </Form.Group>
              )}

              {lead.customerType === 'SOCIETY' && (
                <Form.Group as={Col} lg="4" controlId="formGridSocietyName" className="my-1">
                  <Form.Label>Society Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Society Name"
                    name='customerTypeName'
                    value={lead.customerTypeName}
                    onChange={handleChange}
                  />
                </Form.Group>
              )}
            </Row>
            <div>
        <Row className="mb-3 g-3">
          <Form.Label>Customer Requirement</Form.Label>
        </Row>
        <div>
          {!showForm && (
            <IconButton
              variant="falcon-primary"
              size="sm"
              icon="plus"
              onClick={() => setShowForm(true)} // Show form on click
              transform="shrink-3"
              className="mb-3"
            >
              Add Lead Requirement
            </IconButton>
          )}

          {showForm && (
            <>
              {leadRequirements.map((requirement, index) => (
                <Row key={index} className="mb-3 g-3 border border-200 rounded my-1">
                  {/* Lead Requirement Type */}
                  <Form.Group as={Col} lg={3} controlId={`formGridCategoryType${index}`}>
                    <Form.Label>Lead Requirement Type</Form.Label>
                    <Form.Select
                      className="mb-2"
                      name="categoryType"
                      value={requirement.productName}
                      onChange={(event) => handleAdd(index, event)}
                    >
                      <option value="" disabled hidden>Products</option>
                      <option value="TSHIRT">T-Shirt</option>
                      <option value="HOODIE">Hoodie</option>
                      <option value="JACKET">Jacket</option>
                      <option value="SWEATSHIRT">Sweatshirt</option>
                      <option value="BAG">Bag</option>
                      <option value="MASK">Mask</option>
                      <option value="VEST">Vest</option>
                      <option value="TANK_TOP">Tank Top</option>
                      <option value="Others">Others</option>
                    </Form.Select>
                  </Form.Group>

                  {/* Quantity */}
                  <Form.Group as={Col} lg={1} controlId={`formGridQuantity${index}`} className="mx-1">
                    <Form.Label>Quantity</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Quantity"
                      name="quantity"
                      value={requirement.quantity}
                      onChange={(event) => handleAdd(index, event)}
                    />
                  </Form.Group>

                  {/* Sizes */}
                  {requirement.categoryType !== 'Others' && ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'].map((size) => (
                    <Form.Group as={Col} sm={1} controlId={`formGrid${size}${index}`} className="ms-n3 mx-1" key={size}>
                      <Form.Label>{size}</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder={size}
                        name={size}
                        value={requirement.sizes[size] || ''} // Use empty string if undefined
                        onChange={(event) => handleAdd(index, event)}
                      />
                    </Form.Group>
                  ))}
                  <Form.Group as={Col} lg={3} controlId={`formGridQuantity${index}`} className="mx-1">
                    <Form.Label>Quotations</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="quotation"
                      name="quotation"
                      value={requirement.quotation}
                      onChange={(event) => handleAdd(index, event)}
                    />
                  </Form.Group>
                  {requirement.categoryType === 'Others' && (
                    <>
                      <Form.Group as={Col} lg={4} controlId={`formGridCustomProductName${index}`}>
                        <Form.Label>Custom Product Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter custom product name"
                          name="customProductName"
                          value={requirement.customProductName}
                          onChange={(event) => handleAdd(index, event)}
                        />
                      </Form.Group>

                    </>
                  )}
                  <Col xs="auto" className="align-self-center">
                 <IconButton
                   variant="danger"
                   size="sm"
                   onClick={() => deleteLeadRequirement(index)}
                   title="Delete Requirement"
                 >
                   <FaTrash />
                 </IconButton>
               </Col>
                </Row>
              ))}

                    <IconButton
                      variant="falcon-primary"
                      size="sm"
                      icon="plus"
                      onClick={addLeadRequirement}
                      transform="shrink-3"
                    >
                      Add Lead Requirement
                    </IconButton>
                  </>
                )}
              </div>
            </div>
            <Row className="mb-3 g-3">
              <Form.Label>Lead Details</Form.Label>
            </Row>
            <Row className="mb-3 g-3 border border-200 rounded">
            <Form.Group as={Col} controlId="formGridLeadSource" className="my-1">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                type="text"
                placeholder="notes"
                name='notes'
                value={lead.notes}
                onChange={handleChange}
              />
            </Form.Group>
              <Form.Group as={Col} controlId="formGridLeadSource" className="my-1">
                <Form.Label>Lead Source</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Lead Source"
                  name='leadSource'
                  value={lead.leadSource}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col}  controlId="formGridFollowUpDate" className="my-1">
                <Form.Label>Follow Up Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Follow Up Date"
                  name='followUpDate'
                  value={lead.followUpDate}
                  onChange={handleChange}
                />
              </Form.Group>
              <Row className="mb-3 g-3 ">
                <Form.Group as={Col} controlId="formGridLeadChannel" className="my-1">
                  <Form.Label>Lead Channel</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Lead Channel"
                    name='leadChannel'
                    value={lead.leadChannel}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridPreferredTime" className="my-1">
                  <Form.Label>Preferred Time</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Preferred Time"
                    name='preferredTime'
                    value={lead.preferredTime}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridLanguage" className="my-1">
                  <Form.Label>Language</Form.Label>
                  <Form.Select
                    type="text"
                    placeholder="Language"
                    name='language'
                    value={lead.language}
                    onChange={handleChange}
                  >
                  <option value='' disabled hidden>Language</option>
                  <option value='HIGH'>Hindi</option>
                  <option value='MEDIUM'>English</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridPossibility" className="my-1">
                  <Form.Label>Possibility</Form.Label>
                  <Form.Select
                    className="mb-2"
                    name='possibility'
                    value={lead.possibility}
                    onChange={handleChange}
                  >
                    <option value='' disabled hidden>Possibility</option>
                    <option value='HIGH'>High</option>
                    <option value='MEDIUM'>Medium</option>
                    <option value='LOW'>Low</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              </Row>


            <Button type="submit" variant="primary">Submit</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateLead;
